
import { useAppContext } from '../AppContext';
import { useNavigate } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';


import logo from '../assets/images/logo/company_logo.png';

function Intros() {


 
    const navigate = useNavigate();
    const { currentLanguage, setPage } = useAppContext();
   
    const { ref: aboutRef, inView: aboutInView } = useInView();
    const { ref: businessNicheRef, inView: businessNicheInView } = useInView();
    const { ref: businessApproachRef, inView: businessApproachInView } = useInView();
    const { ref: corePrinciplesRef, inView: corePrinciplesInView } = useInView();
    const { ref: skillsRef, inView: skillsInView } = useInView({ once: true });

    // Add similar refs for other div elements


    const aboutControls = useAnimation();
    const businessNicheControls = useAnimation();
    const businessApproachControls = useAnimation();
    const skillsControls = useAnimation();
    const corePrinciplesControls = useAnimation()
    const [aboutAnimationCompleted, setAboutAnimationCompleted] = useState(false);
    const [businessNicheAnimationCompleted, setBusinessNicheAnimationCompleted] = useState(false);
    const [businessApproachAnimationCompleted, setBusinessApproachAnimationCompleted] = useState(false);
    const [skillsAnimationCompleted, setSkillsAnimationCompleted] = useState(false);
    const [corePrinciplesAnimationCompleted, setCorePrinciplesAnimationCompleted] = useState(false);

    // Using the ternary operator
    function toggle(value) {
        return value === 1 ? -1 : 1;
    }
    useEffect(() => {
        if (aboutInView && !aboutAnimationCompleted) {

            aboutControls.start(i => ({

                opacity: 1,
                y: i * 10,
                x: i * 20,
                transition: { delay: i * 0.1 }
            }));
            setAboutAnimationCompleted(true);
        } else {
            if (aboutInView && aboutAnimationCompleted) {
                aboutControls.start({ opacity: 1, y: 0 });
            } else {
                aboutControls.start({ opacity: 0, y: 50 });
            }
        }
    }, [aboutControls, aboutInView]);

  
    useEffect(() => {
        if (businessNicheInView && !businessNicheAnimationCompleted) {
            businessNicheControls.start(i => ({
                opacity: 1,
                y: 0,

                transition: { delay: i * 0.1 }
            }));
            setBusinessNicheAnimationCompleted(true);
        } else {
            if (businessNicheInView && businessNicheAnimationCompleted) {
                businessNicheControls.start({ opacity: 1, y: 0 });
            } else {
                businessNicheControls.start({ opacity: 0, y: 50 });
            }
        }
    }, [businessNicheControls, businessNicheInView]);


    useEffect(() => {
        if (businessApproachInView && !businessApproachAnimationCompleted) {
            businessApproachControls.start(i => ({
                opacity: 1,
                y: 0,

                transition: { delay: i * 0.1 }
            }));
            setBusinessApproachAnimationCompleted(true);
        } else {
            if (businessApproachInView && businessApproachAnimationCompleted) {
                businessApproachControls.start({ opacity: 1, y: 0 });
            } else {
                businessApproachControls.start({ opacity: 0, y: 50 });
            }
        }
    }, [businessApproachControls, businessApproachInView]);

    useEffect(() => {
        if (corePrinciplesInView && !corePrinciplesAnimationCompleted) {
            corePrinciplesControls.start(i => ({
                opacity: 1,
                y: 0,

                transition: { delay: i * 0.1 }
            }));
            setCorePrinciplesAnimationCompleted(true);
        } else {
            if (corePrinciplesInView && corePrinciplesAnimationCompleted) {
                corePrinciplesControls.start({ opacity: 1, y: 0 });
            } else {
                corePrinciplesControls.start({ opacity: 0, y: 50 });
            }
        }
    }, [corePrinciplesControls, corePrinciplesInView]);

    useEffect(() => {

        if (skillsInView && !skillsAnimationCompleted) {

            skillsControls.start(i => ({
                opacity: 1,
                y: 0,
                transition: { delay: i * 0.1 }
            }));
            setSkillsAnimationCompleted(true);
        } else {
            if (skillsInView && skillsAnimationCompleted) {
                skillsControls.start({ opacity: 1, y: 0 });
            } else {
                skillsControls.start({ opacity: 0, y: 50 });
            }
        }
    }, [skillsControls, skillsInView]);



    // Similar useEffect blocks for other div elements using their respective refs and controls



    return (
        <>
            {currentLanguage === 'English' && (
                <div className='intro-container'
               >
                    <h2 className='sub-header'>Bits About Us</h2>

                    <div className='intro-subcontainer' ref={aboutRef}>
                        <div className='intros'>


                            <div className='intro-item quick-facts'>
                                <motion.h3
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={aboutControls}
                                    custom={0} // No delay for header

                                >
                                    Quick Facts
                                </motion.h3>
                                <motion.ul
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={aboutControls}
                                    custom={1} // Delay for the entire list

                                >
                                    {['Help Ethiopian businesses/enterprises thrive through technology','Innovative software and technology services','Founded in 2023', '+7 years of experience',].map((item, index) => (
                                        <motion.li
                                            key={item}
                                            initial={{ opacity: 0, y: 100 }}
                                            animate={aboutControls}
                                            custom={index + 2}

                                        >
                                            {item}
                                        </motion.li>
                                    ))}
                                </motion.ul>
                            </div>
                           
                            <motion.div  
                             initial={{ opacity: 0, y: 100 }}
                             animate={aboutControls}
                             custom={1}
                             >
                                
                                <img src={logo} alt='company logo for brosfe.' />
                            </motion.div>
                        </div>
                        <div className='intro-item customized-item' ref={businessNicheRef}>
                            <motion.h3
                                initial={{ opacity: 0, y: 100 }}
                                animate={businessNicheControls}
                                custom={0} // No delay for header

                            >
                                Business Niche
                            </motion.h3>
                            <motion.ul
                                initial={{ opacity: 0, y: 100 }}
                                animate={businessNicheControls}
                                custom={1} // Delay for the entire list

                            >
                                {['Office','Ecommerce', 'Social', 'Tourism', 'Education', 'Automation'].map((item, index) => (
                                    <motion.li
                                        key={item}
                                        initial={{ opacity: 0, y: 100 }}
                                        animate={businessNicheControls}
                                        custom={index + 2} // Individual delays for list items

                                    >
                                        {item}
                                    </motion.li>
                                ))}
                            </motion.ul>
                        </div>
                        <div className='intro-item customized-item' ref={businessApproachRef}>
                            <motion.h3
                                initial={{ opacity: 0, y: 100 }}
                                animate={businessApproachControls}
                                custom={0} // No delay for header

                            >
                                Business Approaches
                            </motion.h3>
                            <motion.ul
                                initial={{ opacity: 0, y: 100 }}
                                animate={businessApproachControls}
                                custom={1} // Delay for the entire list

                            >
                                {['Professional', 'Structured', 'Timely followup', 'Cooperative','Tailored design', 'On-time delivery',
                                    ].map((item, index) => (
                                        <motion.li
                                            key={item}
                                            initial={{ opacity: 0, y: 100 }}
                                            animate={businessApproachControls}
                                            custom={index + 2}
                                            style={{ y: index * 20, originY: 1 }}

                                        >
                                            {item}
                                        </motion.li>
                                    ))}
                            </motion.ul>
                        </div>
                        <div className='intro-item customized-item' ref={corePrinciplesRef}>
                            <motion.h3
                                initial={{ opacity: 0, y: 100 }}
                                animate={corePrinciplesControls}
                                custom={0} // No delay for header

                            >
                                Core Principles
                            </motion.h3>
                            <motion.ul
                                initial={{ opacity: 0, y: 100 }}
                                animate={corePrinciplesControls}
                                custom={1} // Delay for the entire list

                            >
                                {['Curiosity', 'Imagination', 'Critical Thinking','Creativity', 'Perseverance',
                                    ].map((item, index) => (
                                        <motion.li
                                            key={item}
                                            initial={{ opacity: 0, y: 100 }}
                                            animate={corePrinciplesControls}
                                            custom={index + 2}
                                            style={{ y: index * 20, originY: 1 }}

                                        >
                                            {item}
                                        </motion.li>
                                    ))}
                            </motion.ul>
                        </div>
                        <div className='intro-item customized-item' ref={skillsRef}>
                            <motion.h3
                                initial={{ opacity: 0, y: 100 }}
                                animate={skillsControls}
                                custom={0} // No delay for header
                                style={{display:'block'}}
            

                            >
                                Expertise
                            </motion.h3>
                            <motion.h4
                            style={{margin:'24px auto 12px auto'}}>
                                Softwares and tools
                            </motion.h4>
                            <motion.ul
                                initial={{ opacity: 0, y: 100 }}
                                animate={skillsControls}
                                custom={1} // Delay for the entire list

                            >
                                {[
                                    'JavaScript',
                                    'Python',
                                    'React Js',
                                    'Express Js',
                                    'Django',
                                    'MongoDB',
                                    'SQL',
                                    'Node Js',
                                    'CSS',
                                    'Sass',
                                    'HTML',
                                    'Framer Motion',
                                    'Figma',
                                    'Adobe Photoshop',
                                    'Canva',
                                    'Adobe XD',
                                ].map((item, index) => (
                                    <motion.li
                                        key={item}
                                        initial={{ opacity: 0, y: 100 }}
                                        animate={skillsControls}
                                        custom={index + 2}

                                    >
                                        {item}
                                    </motion.li>
                                ))}
                            </motion.ul>
                            <motion.h4
                            style={{margin:'24px auto 12px auto'}}>
                                Management and Problem Solving
                            </motion.h4>
                            <motion.ul
                                initial={{ opacity: 0, y: 100 }}
                                animate={skillsControls}
                                custom={1} // Delay for the entire list

                            >
                                {[
                                    'McKinsey problem solving',
                                    '80/20 principle',
                                    'Eisenhower Matrix',
                                    'EPIC for communication',
                                    'Storytelling',
                                    
                                    
                                    
                                ].map((item, index) => (
                                    <motion.li
                                        key={item}
                                        initial={{ opacity: 0, y: 100 }}
                                        animate={skillsControls}
                                        custom={index + 2}

                                    >
                                        {item}
                                    </motion.li>
                                ))}
                            </motion.ul>
                        </div>





                    </div>


                </div>

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='intro-container'
                >
                     <h2 className='sub-header'>ስለኛ በትንሹ</h2>
 
                     <div className='intro-subcontainer' ref={aboutRef}>
                         <div className='intros'>
 
 
                             <div className='intro-item quick-facts'>
                                 <motion.h3
                                     initial={{ opacity: 0, y: 100 }}
                                     animate={aboutControls}
                                     custom={0} // No delay for header
 
                                 >
                                     ፈጣን እውነታዎች
                                 </motion.h3>
                                 <motion.ul
                                     initial={{ opacity: 0, y: 100 }}
                                     animate={aboutControls}
                                     custom={1} // Delay for the entire list
 
                                 >
                                     {['የኢትዮጵያ ቢዝነሶች/ኢንተርፕራይዞች በቴክኖሎጂ እንዲጎለብቱ መርዳት','የፈጠራ ሶፍትዌር እና የቴክኖሎጂ አገልግሎቶች','በ2016 ዓ.ም ተመሠረተ', '+7 ዓመት የሥራ ልምድ',].map((item, index) => (
                                         <motion.li
                                             key={item}
                                             initial={{ opacity: 0, y: 100 }}
                                             animate={aboutControls}
                                             custom={index + 2}
 
                                         >
                                             {item}
                                         </motion.li>
                                     ))}
                                 </motion.ul>
                             </div>
                            
                             <motion.div  
                              initial={{ opacity: 0, y: 100 }}
                              animate={aboutControls}
                              custom={1}
                              >
                                 
                                 <img src={logo} alt='company logo for brosfe.' />
                             </motion.div>
                         </div>
                         <div className='intro-item customized-item' ref={businessNicheRef}>
                             <motion.h3
                                 initial={{ opacity: 0, y: 100 }}
                                 animate={businessNicheControls}
                                 custom={0} // No delay for header
 
                             >
                                 ዒላማ ንግድ/ሥራ ዓይነቶች
                             </motion.h3>
                             <motion.ul
                                 initial={{ opacity: 0, y: 100 }}
                                 animate={businessNicheControls}
                                 custom={1} // Delay for the entire list
 
                             >
                                 {['ቢሮ','ኢኮሜርስ', 'ማህበራዊ አገልግሎት', 'ቱሪዝም', 'ትምህርት', 'አውቶሜሽን'].map((item, index) => (
                                     <motion.li
                                         key={item}
                                         initial={{ opacity: 0, y: 100 }}
                                         animate={businessNicheControls}
                                         custom={index + 2} // Individual delays for list items
 
                                     >
                                         {item}
                                     </motion.li>
                                 ))}
                             </motion.ul>
                         </div>
                         <div className='intro-item customized-item' ref={businessApproachRef}>
                             <motion.h3
                                 initial={{ opacity: 0, y: 100 }}
                                 animate={businessApproachControls}
                                 custom={0} // No delay for header
 
                             >
                                 የሥራ አቀራረባችን
                             </motion.h3>
                             <motion.ul
                                 initial={{ opacity: 0, y: 100 }}
                                 animate={businessApproachControls}
                                 custom={1} // Delay for the entire list
 
                             >
                                 {['ፕሮፌሽናል', 'የተዋቀረ', 'ወቅታዊ ክትትል', 'የህብረት ስራ','የተበጀ ንድፍ', 'በጊዜ የሚደርስ',
                                     ].map((item, index) => (
                                         <motion.li
                                             key={item}
                                             initial={{ opacity: 0, y: 100 }}
                                             animate={businessApproachControls}
                                             custom={index + 2}
                                             style={{ y: index * 20, originY: 1 }}
 
                                         >
                                             {item}
                                         </motion.li>
                                     ))}
                             </motion.ul>
                         </div>
                         <div className='intro-item customized-item' ref={corePrinciplesRef}>
                             <motion.h3
                                 initial={{ opacity: 0, y: 100 }}
                                 animate={corePrinciplesControls}
                                 custom={0} // No delay for header
 
                             >
                                 መርሆዎች
                             </motion.h3>
                             <motion.ul
                                 initial={{ opacity: 0, y: 100 }}
                                 animate={corePrinciplesControls}
                                 custom={1} // Delay for the entire list
 
                             >
                                 {['የማወቅ ጉጉት', 'ምናባዊ ሃሳብ', 'ሂሳዊ አስተሳሰብ', 'ፈጠራ','ጽናት',
                                     ].map((item, index) => (
                                         <motion.li
                                             key={item}
                                             initial={{ opacity: 0, y: 100 }}
                                             animate={corePrinciplesControls}
                                             custom={index + 2}
                                             style={{ y: index * 20, originY: 1 }}
 
                                         >
                                             {item}
                                         </motion.li>
                                     ))}
                             </motion.ul>
                         </div>
                         <div className='intro-item customized-item' ref={skillsRef}>
                             <motion.h3
                                 initial={{ opacity: 0, y: 100 }}
                                 animate={skillsControls}
                                 custom={0} // No delay for header
                                 style={{display:'block'}}
             
 
                             >
                                 ሙያዎቻችን
                             </motion.h3>
                             <motion.h4
                             style={{margin:'24px auto 12px auto'}}>
                                 ሶፍትዌሮችና መሳሪያዎች
                             </motion.h4>
                             <motion.ul
                                 initial={{ opacity: 0, y: 100 }}
                                 animate={skillsControls}
                                 custom={1} // Delay for the entire list
 
                             >
                                 {[
                                     'JavaScript',
                                     'Python',
                                     'React Js',
                                     'Express Js',
                                     'Django',
                                     'MongoDB',
                                     'SQL',
                                     'Node Js',
                                     'CSS',
                                     'Sass',
                                     'HTML',
                                     'Framer Motion',
                                     'Figma',
                                     'Adobe Photoshop',
                                     'Canva',
                                     'Adobe XD',
                                 ].map((item, index) => (
                                     <motion.li
                                         key={item}
                                         initial={{ opacity: 0, y: 100 }}
                                         animate={skillsControls}
                                         custom={index + 2}
 
                                     >
                                         {item}
                                     </motion.li>
                                 ))}
                             </motion.ul>
                             <motion.h4
                             style={{margin:'24px auto 12px auto'}}>
                                 አስተዳደርና የሥራ አቀራረብ
                             </motion.h4>
                             <motion.ul
                                 initial={{ opacity: 0, y: 100 }}
                                 animate={skillsControls}
                                 custom={1} // Delay for the entire list
 
                             >
                                 {[
                                     'McKinsey problem solving',
                                     '80/20 principle',
                                     'Eisenhower Matrix',
                                     'EPIC for communication',
                                     'Storytelling',
                                     
                                     
                                     
                                 ].map((item, index) => (
                                     <motion.li
                                         key={item}
                                         initial={{ opacity: 0, y: 100 }}
                                         animate={skillsControls}
                                         custom={index + 2}
 
                                     >
                                         {item}
                                     </motion.li>
                                 ))}
                             </motion.ul>
                         </div>
 
 
 
 
 
                     </div>
 
 
                 </div>


            )}

        </>

    );
}

export default Intros;






