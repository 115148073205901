
import { motion, AnimatePresence } from 'framer-motion';

import React from 'react';

const SlideIn = () => (
  <motion.div
    key="transit-in"
    className='transit-in'
    initial={{ scaleY: 1 }}
    animate={{ scaleY: 0}}
    exit={{ scaleY: 1 }}
    transition={{ duration: 3, ease: [0.22, 1, 0.36, 1] }}
    style={{display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}
  >
     
    </motion.div>
);

const SlideOut = ({componentName}) => (
  <motion.div
    key="transit-out"
    className='transit-out'
    initial={{ scaleY: 1 }}
    animate={{ scaleY: 0 }}
    exit={{ scaleY: 0 }}
    transition={{ duration: 3, ease: [0.22, 1, 0.36, 1] }}
    style={{display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}
  >
    <>
    {componentName && <h2 style={{fontSize:'60px',color:'#C7E6D7',
  backgroundColor:'#474554', borderRadius:'20px',fontFamily:'Playfair Display',
  }}>{componentName}</h2>}
    </>
    
    </motion.div>
);

function Transition(MyComponent,componentName) {

 return () => (
    <AnimatePresence mode="concurrent">
      <MyComponent key={componentName}/>
      <SlideIn key={`slide-in-${componentName}`}/>
      <SlideOut componentName={componentName} key={`slide-out-${componentName}`}/>
    </AnimatePresence>
  );
}

export default Transition;
