// Testimonies.js
import './TechTestimonies.css'; // Import or create a CSS file for styling


import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';


// Testimonies.js
import React, { useRef, useState, useEffect } from 'react';

import { motion, useAnimation } from 'framer-motion';
import { useAppContext } from '../AppContext';



const SelectedQuotesEN = [
    {
        quote: "Our industry does not respect tradition - it only respects innovation.",
        author: "Satya Nadella, CEO of Microsoft",
        id: 1,
    },
    {
        quote: "Technology has the incredible ability to make us all equal by offering equal access to information and communication.",
        author: "Sundar Pichai, CEO of Alphabet Inc",
        id: 2,
    },
    {
        quote: "It’s no longer the big beating the small, but the fast beating the slow.",
        author: "Eric Pearson, CIO of International Hotel Group (IHG)",
        id: 3,
    },

    {
        quote: "The digital transformation is no longer just an option, it’s a necessity.",
        author: "Emmanuel Macron, President of France",
        id: 7,
    },
    {
        quote: "The digital revolution is far more significant than the invention of writing or even of printing.",
        author: "Douglas Engelbart, American engineer and inventor",
        id: 4,
    },
    {
        quote: "The internet is becoming the town square for the global village of tomorrow.",
        author: "Bill Gates, Co-founder of Microsoft",
        id: 5,
    },
    {
        quote: "The future is digital. It’s not just a trend, it’s simply the way things are.",
        author: "Amit Kalantri, Indian author and entrepreneur",
        id: 6,
    },
    {
        quote: "90% of CEOs believe the digital economy will impact their industry, but less than 15% are executing on a digital strategy.",
        author: "Amit Sloan and Capgemini",
        id: 11,
    },



];

const SelectedQuotesAM = [
    {
        quote: "የእኛ ኢንዱስትሪ ባህልን አያከብርም - ፈጠራን እንጂ.",
        author: "ሳትያ ናዴላ, የማይክሮሶፍት ዋና ሥራ አስፈፃሚ",
        id: 1,
    },
    {
        quote: "ቴክኖሎጂ እኩል የመረጃ እና የመገናኛ ዘዴዎችን በማቅረብ ሁላችንም እኩል እንድንሆን የሚያስችል አስደናቂ ችሎታ አለው።",
        author: "ሱንደር ፒቻይ, Alphabet Inc. ዋና ሥራ አስፈፃሚ",
        id: 2,
    },
    {
        quote: "ከአሁን በኋላ ትልቁ ትንሹን አያሸንፍም፤ ፈጣኑ ቀርፋፋውን እንጂ።",
        author: "ኤሪክ ፒርሰን, የ International Hotel Group (IHG) ዋና የመረጃ ኦፊሰር",
        id: 3,
    },

    {
        quote: "ዲጂታል ትራንስፎርሜሽን አማራጭ ብቻ ሳይሆን አስፈላጊ ነው፡፡",
        author: "ኢማኑኤል ማክሮን, የፈረንሳይ ፕሬዝደንት",
        id: 7,
    },
    {
        quote: "የዲጂታል አብዮት ከጽሑፍ ፈጠራም ሆነ የህትመት ፈጠራ በላይ ዋጋ አለው፡፡",
        author: "ዳግላስ ኤንገልባርት, አሜሪካዊ ኢንጅነርና የፈጠራ ባለሙያ",
        id: 4,
    },
    {
        quote: "ኢንተርኔት ለነገው ዓለም አቀፋዊ መንደር የከተማ አደባባይ እየሆነ ነው።",
        author: "ቢል ጌትስ, የMicrosoft መስራች",
        id: 5,
    },
    {
        
        quote: "የነገው ዓለም ዲጂታል ነው፡፡ ይህ ደግሞ ጊዜያዊ ሞቅታ ሳይሆን ቀጣይና ቋሚ ነው፡፡",
        author: "አሚት ካላንድሪ, ህንዳዊ ጸሓፊና አንተርፕርነር",
        id: 6,
    },
    {
        quote: "90% ዋና ሥራ አስፈፃሚዎች የዲጂታል ኢኮኖሚ በኢንዱስትሪዎቻቸው ላይ ተጽዕኖ እንደሚያሳድር ያምናሉ ነገር ግን ከነኚህ ውስጥ ከ15% ያነሱ ናቸው የዲጂታል ስትራቴጂ እየፈጸሙ ያሉት ።",
        author: "አሚት ስሎንና ካፕጄሚኒ",
        id: 11,
    },



];



const TechTestimonies = () => {
    const { currentLanguage, setPage } = useAppContext();
    const [currentIndex, setCurrentIndex] = useState(0);
    const SelectedQuotes = currentLanguage==='English'?SelectedQuotesEN:SelectedQuotesAM


    const TechTestimonyCard = ({ testimony, index,}) => {
        return (
            <div style={{display:'inline-block',
        }}>
            <div  className='tech-testimony-container'
            >
                <div className='tech-quote'>
                    <p>{testimony.quote}</p>
                </div>
                <div className='tech-author'>
                    <p>{testimony.author}</p>
                </div>
                <div style={{position:'absolute',width:'50px',
            height:'50px',borderRadius:'50%',bottom:'-40px',left:'-20px',
            backgroundColor:'#ddd'}}>

                </div>
                <div style={{position:'absolute',width:'50px',
            height:'50px',borderRadius:'50%',top:'-25px',right:'-30px',
            backgroundColor:'#ddd'}}>

                </div>
            </div>
            </div>
        );
    };
    const currentIndexRef = useRef(0);
    const [refQuotes, inView] = useInView();
    useEffect(() => {
        const intervalId = setInterval(() => {
            inView && setCurrentIndex(currentIndexRef.current);
            currentIndexRef.current = (currentIndexRef.current + 1) % SelectedQuotes.length;

        }, 4000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [setCurrentIndex, SelectedQuotes.length, inView]);



    const { ref: testimoniesRef, inView: testimoniesInView } = useInView();

    const [testimoniesAnimationCompleted, setTestimoniesAnimationCompleted] = useState(false);

    const testimoniesControls = useAnimation();

    useEffect(() => {
        if (testimoniesInView && !testimoniesAnimationCompleted) {

            testimoniesControls.start({

                opacity: 1,
                y: 0,
                x: [0, -10, 10, -10, 10, 0],

                transition: { delay: 0.5 }
            });
            setTestimoniesAnimationCompleted(true);
        } else {
            if (testimoniesInView && testimoniesAnimationCompleted) {
                testimoniesControls.start({ opacity: 1, y: 0, x: 0 });
            } else {
                testimoniesControls.start({ opacity: 0, y: 50, x: 0 });
            }
        }
    }, [testimoniesControls, testimoniesInView]);


    const handleSlideClick = (direction) => {
        var slider = document.getElementById('slider');
       
        const scrollDistance = 60;
        if(direction === 'left'){
            
            
            // slider.scrollLeft = slider.scrollLeft - 600;
            slider.scrollLeft -= slider.clientWidth * (scrollDistance / 100);
        }
        else if(direction === 'right'){
            // slider.scrollLeft = slider.scrollLeft + 600;
            slider.scrollLeft += slider.clientWidth * (scrollDistance / 100);
        }
      
}


const SlideLeft = ({ handleSlideClick }) => (
    <div className='slider-button' style={{  zIndex: 12, 
    }} onClick={() => handleSlideClick('left')}
    >
        <FontAwesomeIcon icon={faArrowLeft} className="arrow-cycle" />
    </div>
);

const SlideRight = ({ handleSlideClick }) => (
    <div className='slider-button' style={{ zIndex: 11 }} onClick={() => handleSlideClick('right')}>
        <FontAwesomeIcon icon={faArrowRight} className="arrow-cycle" />
    </div>
);

const header_title = currentLanguage === 'English' ? 'Insights from Leaders on Digitization' : 'በዲጂታይዜሽን ላይ ከተጽዕኖ ፈጣሪዎች የተገኙ ግንዛቤዎች';


    return (
        <motion.div ref={testimoniesRef}
            initial={{ opacity: 0, y: 100 }}
            animate={testimoniesControls}
            custom={1}
        >

            <div ref={refQuotes} className='tech-main-container'>
                <h2 className='sub-header'>{header_title}</h2>
                <div style={{position:'relative', display:'grid',justifyContent:'center',
            alignItems:'center', margin:'auto',
           boxShadow:'0 4px 12px rgba(170, 170, 255, 0.5)'}}
            className='tech-grid-container'>
                    <SlideLeft handleSlideClick={handleSlideClick} key="slide-left" />
                    <div id='slider'
                    style = {{
                        width: '100%',
                        height: '100%',
                        overflowX: 'scroll',
                        overflowY: 'hidden',
                        whiteSpace: 'nowrap',
                        scrollBehavior: 'smooth',
                       
                        display:'flex',
                        justifyContent:'flex-start',
                        gap:'10px',
                        alignItems:'center',
                        padding:'auto 100px',     
                                           
                        
                       
                      }}
                    >
                      
                        {SelectedQuotes.map((testimony, index) => (
                        <TechTestimonyCard testimony={testimony} index={index}  key={`testimony-${index}`}/>
                    ))}

                                       

                    </div>
                   
                    <SlideRight handleSlideClick={handleSlideClick} key="slide-right" />
                </div>

            </div>
           
        </motion.div>

    );
};

export default TechTestimonies