import React, { useState, useEffect, useRef } from 'react';

import emailjs from '@emailjs/browser';

import FormStep from './FormStep';
import { useAppContext } from '../AppContext';
const ReachYouForm = () => {

    const { currentLanguage, setPage } = useAppContext();
    const [messageSuccess, setMessageSuccess] = useState(false);
    const [messageFail, setMessageFail] = useState(false);
    const popUpSuccess = currentLanguage==="English"
    ?
    "Success! 🌟 We appreciate your message! We'll get back to you ASAP!"
    :
    "ስኬት! 🌟 ለመልእክቶ እናመሰግንዎታለን! በቶሎ ወደ እርስዎ እንመለሳለን!"
    ;
    const popUpFail = currentLanguage==="English"?
    "Error sending your message. We're very sorry. Please try again later. 💔"
    :
    "መልእክትዎን መላክ ባለመቻላችን ይቅርታ እንጠየቅዎታለን። እባክዎን ቆየት ብለው ይሞክሩ. 💔";
    const popUpFormError = currentLanguage === "English"?
    " ❗️ Your name and either your phone number or email helps us connect with you."
    :
    " ❗️ ስምዎትና የስልክ ቁጥርዎ ወይም ኢሜይል ከእርስዎ ጋር ለመገናኘት ይረዳናል።";

    const [formError, setFormError] = useState(false);

    const reachYouForm = useRef();
    // const [formVisibility, setFormVisibility] = useState(0);




    const sendEmail = (e) => {
        e.preventDefault();
        console.log('In sendemail',reachYouForm.current);

        // Basic validation
        const name = reachYouForm.current.from_name.value.trim();
        const email = reachYouForm.current.from_email.value.trim();
        // const phoneNumber = reachYouForm.current.from_number.value.trim();
        const phoneNumber = reachYouForm.current.from_number.value.trim();

        if (!name) {
            // Name is required
            setFormError(true);
            return;
        }

        if (!(email || phoneNumber)) {

            setFormError(true);
            return;
        }

        emailjs.sendForm('service_jmkceih', 'template_0lqwfta', reachYouForm.current, 'qEXTjjNF0-S0US1UE')
            .then((result) => {
                // console.log(result.text);
                setMessageSuccess(true);
            }, (error) => {
                setMessageFail(true);
                // console.log(error.text);
            });
    };

    useEffect(() => {
        if (messageSuccess || messageFail || formError) {
          setTimeout(() => {
            setMessageSuccess(false);
            setMessageFail(false);
            setFormError(false);
            
          }, 5000)
        }
      }, [messageSuccess, messageFail,formError])

    return (
        <>
            <form ref={reachYouForm} onSubmit={sendEmail}>

                <FormStep

                />

            </form>
            <div style={{ position: 'relative', width:'100%' }}>
            {messageSuccess &&
                (
                    <div style={{
                        backgroundColor: '#474554', color: '#aaaaff',
                        width: '100%',
                        position: 'absolute', top: '0', left: '50%',
                        transform: 'translate(-50%,-50%)', padding: '20px'
                    }}>
                        <p>{popUpSuccess}
                        </p>

                    </div>

                )}
            {messageFail &&
                (
                    <div style={{
                        backgroundColor: '#474554', color: '#ffaaaa', width: '40%',
                        position: 'absolute', top: '50%', left: '50%',
                        transform: 'translate(-50%,-50%)', padding: '10px'
                    }}>
                        <p>{popUpFail}
                        </p>

                    </div>

                )}

            </div>
            

        </>

    )


};


export default ReachYouForm;
