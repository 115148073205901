import { FaArrowRight } from 'react-icons/fa';
import { useState } from 'react';
const DynamicInputs = ({ index, inputKey, label, inputValue, updateInputValue, updateFormVisibility, handleInputInfo, formVisibility }) => {


    const [focusInput, setFocusInput] = useState(false);
    const handleBlur = () => {
        const value = handleInputInfo(inputKey.trim());
        if (value === '') {

            setFocusInput(false);
        }

    };
    const YShift = -index * 40;
    return (

        <div
        className={`form-step-container ${formVisibility === index ? 'active' : ''}`}
            style={{
                // visibility: formVisibility === index ? 'visible' : 'hidden',
                transform: `translateY(${YShift}px)`,

            }}>
               

            <div className={`form-step`}>
                <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                  
                    {index === 3 ? (
                        <textarea
                            name={inputKey.trim()}
                            value={inputValue}
                            onChange={(e) => updateInputValue(e.target.value)}
                            onFocus={() => setFocusInput(true)}
                            onBlur={() => handleBlur()}
                            placeholder="Write your message"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            style={{
                                visibility: formVisibility === index ? 'visible' : 'hidden',
                                backgroundColor:'#f5f5f5', textIndent: 10}}
                        />
                    ) : (
                        <input
                            type="text"
                            name={inputKey.trim()}
                            value={inputValue}
                            onChange={(e) => updateInputValue(e.target.value)}
                            onFocus={() => setFocusInput(true)}
                            onBlur={() => handleBlur()}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                }
                              }}
                            style={{textIndent: 10}}
                        />
                    )}

                    <label
                        style={{
                            position: 'absolute',
                            left: '0',
                            bottom: '0',
                            transition: 'all 0.3s ease',
                            transform: focusInput ? 'translateY(-40px)' : 'none',
                            fontSize: focusInput ? '13px' : '16px',
                            textIndent: 10, 


                        }}
                    >
                        {index !== 3 && label}
                    </label>
                </div>
                <div
                    style={{
                        backgroundColor: '#aaaaff',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '20%',
                        cursor: 'pointer',
                    }}
                    className='continue-button'
                    onClick={() => updateFormVisibility(inputKey.trim())}
                >
                    <FaArrowRight />
                </div>
            </div>
        </div>

    )
};
export default DynamicInputs;