import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { useAppContext } from '../AppContext';



function InputForm() {

  const { currentLanguage, setPage } = useAppContext();

  const isMobile = window.innerWidth <= 430;
  const form = useRef();
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [messageFail, setMessageFail] = useState(false);
  const popUpSuccess = currentLanguage==="English"
  ?
  "Success! 🌟 We appreciate your message! We'll get back to you ASAP!"
  :
  "ስኬት! 🌟 ለመልእክቶ እናመሰግንዎታለን! በቶሎ ወደ እርስዎ እንመለሳለን!"
  ;
  const popUpFail = currentLanguage==="English"?
  "Error sending your message. We're very sorry. Please try again later. 💔"
  :
  "መልእክትዎን መላክ ባለመቻላችን ይቅርታ እንጠየቅዎታለን። እባክዎን ቆየት ብለው ይሞክሩ. 💔";
  const popUpFormError = currentLanguage === "English"?
" ❗️ Your name and either your phone number or email helps us connect with you."
:
" ❗️ ስምዎትና የስልክ ቁጥርዎ ወይም ኢሜይል ከእርስዎ ጋር ለመገናኘት ይረዳናል።";

  const [formError, setFormError] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState("");


  const baseURL = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_BASE_URL_PROD
    : process.env.REACT_APP_API_BASE_URL_DEV;

  const REACT_APP_RECAPTCHA_SITE_KEY_DEV = process.env.REACT_APP_RECAPTCHA_SITE_KEY_DEV;
  const REACT_APP_RECAPTCHA_SECRET_KEY_DEV = process.env.REACT_APP_RECAPTCHA_SECRET_KEY_DEV;
  
  const REACT_APP_RECAPTCHA_SITE_KEY_PROD = process.env.REACT_APP_RECAPTCHA_SITE_KEY_PROD;
  const REACT_APP_RECAPTCHA_SECRET_KEY_PROD = process.env.REACT_APP_RECAPTCHA_SECRET_KEY_PROD;
  const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

  const handleCaptchaResponse = (value) => {
    // Send the email with the reCAPTCHA response
    setCaptchaResponse(value);
    emailjs
      .sendForm(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        form.current,
        EMAILJS_PUBLIC_KEY,
        captchaResponse
      )
      .then(
        (result) => {
          setMessageSuccess(true);
          setShowCaptcha(false); // Reset the showCaptcha state after successful submission
        },
        (error) => {
          setMessageFail(true);
          setShowCaptcha(false); // Reset the showCaptcha state after failed submission
        }
      );
  }; 
  
    
  
   

  const sendEmail = (e) => {
    e.preventDefault();

    // Basic validation
    const name = form.current.from_name.value.trim();
    const email = form.current.from_email.value.trim();
    const phoneNumber = form.current.from_number.value.trim();

    if (!name || (!phoneNumber && !email)) {
      // Name and either phone or email required
      setFormError(true);
      return;
    }

    // Show the reCAPTCHA
    setShowCaptcha(true);
  };
  const handleCTAClick = () => {
    console.log("Nothing to do for now.")
  }


  useEffect(() => {
    if (messageSuccess || messageFail || formError) {
      setTimeout(() => {
        setMessageSuccess(false);
        setMessageFail(false);
        setFormError(false);
        form.current.reset();
      }, 5000)
    }
  }, [messageSuccess, messageFail, formError])



  const CtaContact = () => {
    return (
      <button className="cta-contact" onClick={handleCTAClick}>Send Me a Proforma now</button>
    )
  }


  const { ref: inputsRef, inView: inputsInView } = useInView();

  const [inputsAnimationCompleted, setInputsAnimationCompleted] = useState(false);

  const inputsControls = useAnimation();

  useEffect(() => {
    if (inputsInView && !inputsAnimationCompleted) {

      inputsControls.start({

        opacity: 1,
        y: 0,
        x: [0, -10, 10, -10, 10, 0],

        transition: { delay: 0.5 }
      });
      setInputsAnimationCompleted(true);
    } else {
      if (inputsInView && inputsAnimationCompleted) {
        inputsControls.start({ opacity: 1, y: 0, x: 0 });
      } else {
        inputsControls.start({ opacity: 0, y: 50, x: 0 });
      }
    }
  }, [inputsControls, inputsInView]);

  const [inputValues, setInputValues] = useState({
    from_name: '',
    from_email: '',
    from_number: '',
    message: '',

  });

  const [inputFocus, setInputFocus] = useState({
    from_name: false,
    from_email: false,
    from_number: false,
    message: false,

  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };


  const handleInputFocus = (e) => {
    const name = e.target.name;
    setInputFocus((prevValues) => ({
      ...prevValues,
      [name]: true,
    }));
  };

  const handleInputBlur = (e) => {
    const name = e.target.name;
    setInputFocus((prevValues) => ({
      ...prevValues,
      [name]: false,
    }));
  };
  const header_title = currentLanguage === 'English' ? 'Contact Us' : 'ያግኙን';
  const subheader_title = currentLanguage === 'English' ? 'Drop your inquiry here and get pricing promptly!' 
  : 'ፍላጎትዎን ክሥር ጽፈው ይላኩልንና የዋጋና አሠራር ፕሮፎርማ ያግኙ';

  const formLables = currentLanguage === 'English' ? {
    name: 'Name',
    email: 'Email',
    number: 'Phone Number',
    message: 'Enter your message here.',
    send:'Send',
  } : {
    name: 'ስም',
    email: 'ኢሜይል',
    number: 'ስልክ ቁጥር',
    message: 'መልእክትዎን እዚህ ይጻፉ',
    send:'ላክ',
  
  }

  return (
    <motion.div className="input-form-container" ref={inputsRef}
      initial={{ opacity: 0, y: 100 }}
      animate={inputsControls}
      custom={1}
      style={{ position: 'relative' }}


    >
      <h2 className="sub-header">{header_title}</h2>
      <div className="input-form">

        <h3>{subheader_title}</h3>

        <form ref={form} onSubmit={sendEmail}
          style={{ position: 'relative' }}>
          <div className="input-wrapper">
          
            {showCaptcha && (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "100",
                }}
              >
                <ReCAPTCHA
                  sitekey={REACT_APP_RECAPTCHA_SITE_KEY_PROD}
                  onChange={handleCaptchaResponse}
                
                />
              </div>
            )}
            <div className="input-group">
              <input
                type="text"
                name="from_name"
                value={inputValues.from_name}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={!inputValues.from_name ? handleInputBlur : null}
                style={{ textIndent: 10 }}

              />
              <label
                style={{
                  transform: inputValues.from_name || inputFocus['from_name'] ? 'translateY(-30px)' : 'none',
                  fontSize: inputValues.from_name || inputFocus['from_name'] ? '13px' : '16px',
                  textIndent: 10
                }}>{formLables.name}
              </label>
              <div className="input-underline"
                style={{ transition: 'all 0.3s ease', transform: inputValues.from_name || inputFocus['from_name'] ? 'scaleX(1)' : 'scaleX(0)' }}></div>


            </div>

          </div>

          <div className="input-wrapper">
            <div className="input-group">
              <input
                type="text"
                name="from_email"
                value={inputValues.from_email}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={!inputValues.from_email ? handleInputBlur : null}
                style={{ textIndent: 10 }}
              />
              <label
                style={{
                  transform: inputValues.from_email || inputFocus['from_email'] ? 'translateY(-30px)' : 'none',
                  fontSize: inputValues.from_email || inputFocus['from_email'] ? '13px' : '16px',
                  textIndent: 10
                }}>{formLables.email}</label>
              <div className="input-underline"
                style={{ transition: 'all 0.3s ease', transform: inputValues.from_email || inputFocus['from_email'] ? 'scaleX(1)' : 'scaleX(0)' }}></div>


            </div>

          </div>

          <div className="input-wrapper">
            <div className="input-group">

              <input
                type="text"
                name="from_number"
                value={inputValues.from_number}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={!inputValues.from_number ? handleInputBlur : null}
                style={{ textIndent: 10 }}

              />
              <label
                style={{
                  transform: inputValues.from_number || inputFocus['from_number'] ? 'translateY(-30px)' : 'none',
                  fontSize: inputValues.from_number || inputFocus['from_number'] ? '13px' : '16px',
                  textIndent: 10
                }}>{formLables.number}</label>
              <div className="input-underline"
                style={{ transition: 'all 0.3s ease', transform: inputValues.from_number || inputFocus['from_number'] ? 'scaleX(1)' : 'scaleX(0)' }}></div>
            </div>
          </div>


          <div className="input-wrapper"
            style={{ height: '100px' }}
          >
            <div className="input-group" style={{ height: '100%' }}>
              <textarea name="message"

                placeholder= {formLables.message}                
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={!inputValues.message ? handleInputBlur : null}
                style={{ textIndent: 10 }} />

              <label></label>
              <div className="input-underline"
                style={{
                  transition: 'all 0.3s ease',
                  transform: inputValues.message || inputFocus['message'] ? 'scaleX(1)' : 'scaleX(0)',
                 
                }}
              ></div>
            </div>
          </div>

          <input type="submit" value={formLables.send} className="cta-contact" />
        </form>
      </div>
      {messageSuccess &&
        (
          <div style={{
            backgroundColor: '#474554', color: '#ffd230',
            width: isMobile ? '80%' : '40%',
            position: 'absolute', top: '50%', left: '50%',
            transform: 'translate(-50%,-50%)', padding: '20px'
          }}>
            <p>{popUpSuccess}
            </p>

          </div>

        )}
      {messageFail &&
        (
          <div style={{
            backgroundColor: '#474554', color: '#ffaaaa', 
            width: isMobile ? '80%' : '40%',
            position: 'absolute', top: '50%', left: '50%',
            transform: 'translate(-50%,-50%)', padding: '10px'
          }}>
            <p>{popUpFail}
            </p>

          </div>

        )}

      {formError &&
        (
          <div style={{
            backgroundColor: '#474554', color: '#ffaaaa', 
            width: isMobile ? '80%' : '40%',
            position: 'absolute', top: '50%', left: '50%',
            transform: 'translate(-50%,-50%)', padding: '10px',
            zIndex: '105',
          }}>
            <p>{popUpFormError}
            </p>

          </div>

        )}


    </motion.div>




  );
}
export default InputForm;