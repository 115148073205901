import './ProductLifeCycle.css';
import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';

import plc1 from '../assets/images/product_lifecycle/plc1.png';
import plc2 from '../assets/images/product_lifecycle/plc2.png';
import plc3 from '../assets/images/product_lifecycle/plc3.png';
import plc4 from '../assets/images/product_lifecycle/plc4.png';
import plc5 from '../assets/images/product_lifecycle/plc5.png';
import plc6 from '../assets/images/product_lifecycle/plc6.png';
import plc7 from '../assets/images/product_lifecycle/plc7.png';
import plc8 from '../assets/images/product_lifecycle/plc8.png';
import plc9 from '../assets/images/product_lifecycle/plc9.png';
import plc10 from '../assets/images/product_lifecycle/plc10.png';

const StackedCard = ({ cycle, index, topCardIndex }) => {

    
    const cardRotations = [

        {
            id: '01',
            rotation: 0,
        },
        {
            id: '02',
            rotation: 5,
        },
        {
            id: '03',
            rotation: 0,
        },
        {
            id: '04',
            rotation: 5,
        }, {
            id: '05',
            rotation: 0,
        },
        {
            id: '06',
            rotation: 5,
        },
        {
            id: '07',
            rotation: 0,
        },
        {
            id: '08',
            rotation: 5,
        },
        {
            id: '09',
            rotation: 0,
        },
        {
            id: '10',
            rotation: 5,
        },
    ];
    const cardControls = useAnimation();

    useEffect(() => {
        const isVisible = index >= topCardIndex;
        const isTopCard = index === topCardIndex;

        const offsetX = 80 - (index * 2);
        const offsetXDefault = index * 2;
        const randomYOffset = [-60, 0, 60][Math.floor(Math.random() * 3)]; // Randomly pick -30, 0, or 30
        // const baseZIndex = 10;


        if (isVisible) {
            cardControls.start({
                x: isTopCard ? '0%' : `-${offsetXDefault}%`,
                y: '0%',
                opacity: 1,
                transition: { duration: isTopCard ? 1 : 1 }, // Transition only for top card movement
            });
        } else {
            cardControls.start({
                x: `${window.innerWidth}px`,
                y: `${randomYOffset}%`,// Use window.innerWidth for screen width
                opacity: 0.5,
                transition: { duration: 1 },
            });
        }
    }, [index, topCardIndex, cardControls]);

    const { ref: cardItemRef, inView: cardItemsInView } = useInView();
    const cardItemsControls = useAnimation();
    const [cardItemsAnimationCompleted, setCardItemsAnimationCompleted] = useState(false);
    useEffect(() => {
        if (cardItemsInView && !cardItemsAnimationCompleted) {

            cardItemsControls.start({

                opacity: 1,
                x: 0,


                transition: { delay: 0.5 }
            });
            setCardItemsAnimationCompleted(true);
        } else {
            if (cardItemsInView && cardItemsAnimationCompleted) {
                cardItemsControls.start({ opacity: 1, x: 0 });
            } else {
                cardItemsControls.start({ opacity: 0, x: 0 });
            }
        }
    }, [cardItemsControls, cardItemsInView]);

    const backgroundColors = ['#FFD700', '#aaffaa', '#ffffaa', '#ffaaaa', '#7ec8e3'];

    return (
        <motion.div
            style={{
              
                zIndex: 10 - index,

            }}

            animate={cardControls}
            custom={1}

            className='cards'

        >
            <motion.div className='cardMotion'

            >
                <div className='card-item'
                    style={{ overflow: 'hidden',border:'2px solid',
                    borderColor: backgroundColors[index%backgroundColors.length],
                    }}>
                    <p
                    style={{position:'absolute',top:'20%',left:'10%'}}><span className='card-item-id'>{cycle.id}</span> {cycle.type}</p>
                    <div
                        style={{
                            width: '50px', height: '50px',
                            backgroundColor:backgroundColors[index%backgroundColors.length],
                            borderRadius: '50%', position: 'absolute',
                            top: '-25px', right: '-10%', zIndex: '8'
                        }}></div>
                    <div
                        style={{
                            width: '100px', height: '100px',
                            backgroundColor:backgroundColors[index%backgroundColors.length],
                            borderRadius: '50%', position: 'absolute',
                            bottom: '-50px', left: '0', zIndex: '8'
                        }}></div>
                    <div
                        style={{
                            width: '100px', height: '100px',
                            backgroundColor: index%2==0?'#ffd230':'#C7E6D7',
                            // backgroundColor:'#C7E6D7',
                            borderRadius: '50%', position: 'absolute',
                            bottom: '-80px', left: '60px', zIndex: '9'
                        }}></div>

                </div>

            </motion.div>
        </motion.div>
    );
};

const Arrows = ({ handleArrowClick }) => (
    <div style={{
        position: 'relative',  zIndex: '11', 
        display: 'flex', justifyContent: 'space-between',
       
    }}
    className='lifecycle-control-container'>
        <div style={{  zIndex: '12' }} className='card-button' onClick={() => handleArrowClick('left')}>
            <FontAwesomeIcon icon={faArrowLeft} className="arrow-cycle" />
        </div>

        <div style={{ zIndex: '11' }} className='card-button' onClick={() => handleArrowClick('right')}>
            <FontAwesomeIcon icon={faArrowRight} className="arrow-cycle" />
        </div>
    </div>
);
const ProductLifeCycle = () => {
    const { currentLanguage, setPage } = useAppContext();
    const ProductLifeCycleMapEN = [
        {
            id: '01',
            type: 'Kickoff Magic',
            functions: 'Let\'s kick things off with a friendly chat. We\'ll dive into your dreams, goals,' +
                ' and timelines. Together, we\'ll define the project scope and set the stage for success.',
            image: plc1,
        },
        {
            id: '02',
            type: 'Lean Discovery',
            functions: 'We take a quick journey to understand the local and global market and your competition.' +
                'We\'ll define a sleek MVP with essential features, ensuring we focus on what matters most.',
            image: plc2,
        },
        {
            id: '03',
            type: 'Design Sprints',
            functions: 'Get ready for a design rollercoaster! We\'ll rapidly prototype and iterate, ensuring your website, app, office software and brand design are not just pretty but also effective.',
            image: plc3,
        },
        {
            id: '04',
            type: 'Agile Development Thrills',
            functions: 'Buckle up for agile development. We build in iterations, releasing regularly for your feedback. ' +
                'It\'s all about bringing your vision to life, one sprint at a time.',
            image: plc4,
        },
        {
            id: '05',
            type: 'Feedback Fiesta',
            functions: 'Testing, testing, 1-2-3. We conduct user testing sessions, gather feedback, and make adjustments on the fly. Your satisfaction is our priority.',
            image: plc5,
        },
        {
            id: '06',
            type: 'Brand Harmony',
            functions: 'Your brand shines across all touchpoints. We ensure consistency and iterate based on user responses. A harmonious brand is a powerful one.',
            image: plc6,
        },
        {
            id: '07',
            type: 'AI Wizardry',
            functions: 'We sprinkle in AI magic where it matters. Incremental integration, continuous refinement, and staying ahead of the AI curve. We utilize Applied AI and '+
            'consultancy to explore optimal and better reaches.',
            image: plc7,
        },
        {
            id: '08',
            type: 'Agile Management',
            functions: 'We manage projects with agile finesse. Regular reviews, adjustments, and flexibility keep things flowing smoothly.',
            image: plc8,
        },
        {
            id: '09',
            type: 'Delivery Celebration',
            functions: 'It\'s the grand finale! We release the final product incrementally, ' +
                'celebrate milestones, and ensure a smooth deployment.',
            image: plc9,
        },
        {
            id: '10',
            type: 'Post-Launch Adventure',
            functions: 'After liftoff, we continue the journey. User feedback guides our post-launch ' +
                'optimization. Your success is an ongoing story.',
            image: plc10,

        },




    ];
    const ProductLifeCycleMapAM = [
        {
            id: '01',
            type: 'ፕሮጀክት በይፋ እንጀምራለን',
            functions: 'ነገሮችን በወዳጅነት ጨዋታ እንጀምራለን። ስለ ህልሞችዎ፣ ግቦችዎ እና የጊዜ እቅድዎችዎ እንወያያለን። አንድ ላይ የፕሮጀክቱን ወሰን በግልጽ እናስቀምጣለን በመቀጠልም ለስኬት መድረኩን እናዘጋጃለን።',
            image: plc1,
        },
        {
            id: '02',
            type: 'ቀልጣፋ ግኝት',
            functions: 'የሀገር ውስጥ እና አለም አቀፋዊ ገበያን እና የእርስዎን ውድድር ለመረዳት ፈጣን ጉዞ እናደርጋለን። በጣም አስፈላጊ በሆኑት ሥራዎች ላይ ማተኮር እንዳለብን በማረጋገጥ እነኛንም የሚተገብር አነስተኛ የሥራ መተግበሪያ ሊያሟላቸው የሚገባቸውን ባህሪያት እንገልፃለን።',
      
            image: plc2,
        },
        {
            id: '03',
            type: 'የዲዛይን ሩጫ',
            functions: 'ለዲዛይን ሮለርኮስተር ይዘጋጁ! የእርስዎን ድር ጣቢያ፣ መተግበሪያ፣ የቢሮ ሶፍትዌር እና የምርት ስም ዲዛይን ቆንጆ ብቻ ሳይሆን ውጤታማ መሆናቸውን በማረጋገጥ በፍጥነት እንቀርፃለን ደጋግመንም እንዳስፈላጊነቱ እናርማለን።',
            image: plc3,
        },
        {
            id: '04',
            type: 'ቀልጣፋ የሥራ ግስጋሴዎች',
            functions: 'ለፈጣንና ነጻ የሥራ ሁኔታ ይሠናዱ። ደጋግመን እንገነባለን፣ አስተያየትዎን ነቅተን በመደበኛነት እንሰማለን። ዓላማችን የእርስዎን ራዕይ ወደ ህይወት ማምጣት ነው፣ በአንድ ጊዜ አንድ ሩጫ።',
            image: plc4,
        },
        {
            id: '05',
            type: 'ግብረ መልስ',
            functions: 'ሙክራ፤ ሙክራ 1-2-3. የተጠቃሚ ሙከራ ክፍለ ጊዜዎችን እናካሂዳለን፣ ግብረ መልስ እንሰበስባለን እና በፍጥነትም ማስተካከያዎችን እናደርጋለን። የእርስዎ እርካታ በእኛ ቅድሚያ የሚሰጠው ጉዳይ ነው።',
            image: plc5,
        },
        {
            id: '06',
            type: 'ብራንድ ተዋሃጅነት',
            functions: 'የምርትዎ ብራንድ በሁሉም የመዳሰሻ ነጥቦች ላይ ያበራል። በተጠቃሚ ምላሾች ላይ በመመስረት ወጥነት እና ውህደትን እናረጋግጣለን። የተዋሃደ የምርት ብራንድ ተጽዕኖ ፈጣሪ ነው።',
            image: plc6,
        },
        {
            id: '07',
            type: 'AI አስማት',
            functions: 'አስፈላጊ ሆነው ባገኘናቸው ሥፍራዎች የAI ቀልጣፋና አዋች መፍትሄዎችን አንጠቀማለን፡፡ ትጨማሪ ውህደት፣ ቀጣይነት ያለው ማሻሻያ እና ከ AI የለውጥ ግስጋሴ ተጠቃሚነታችንን እናረጋግጣለን። ተግባራዊ AI እና ኮንሳልታንሲ የተሻሉና አዋጭ ውጤቶችን ይሠጡናል።',

            image: plc7,
        },
        {
            id: '08',
            type: 'ቀልጣፋ አስተዳደር',
            functions: 'ፕሮጀክቶችን በነጻና ተለዋዋጭ አፈፃፀም እናስተዳድራለን። መደበኛ ግምገማዎች፣ ማስተካከያዎች እና ነጻ አቀራረብ ሥራዎቻችን ያለችግር እንዲሄዱ ያደርጋሉ።',
            image: plc8,
        },
        {
            id: '09',
            type: 'የድል በዓል',
            functions: 'ፍጻሜ ነው! የመጨረሻውን ምርት ተራ በተራ እንለቃለን ፣ የሥራ መዳረሻዎችን እናከብራለን፤ በተጨማሪም ሥራችን በአግባቡ ለሥራው ባለቤትና ለተጠቃሚዎቹ በትክክል መድረሡን እናረጋግጣለን።',
            image: plc9,
        },
        {
            id: '10',
            type: 'ድህረ አቅርቦት ጀብዱ',
            functions: 'ከድህረ አቅርቦትም በኋላ ጉዞውን እንቀጥላለን ፡፡ የተጠቃሚ ግብረመልስ የድህረ አቅርቦት ማትባት ሥራችንን ይመራዋል። የእርስዎ ስኬት ቀጣይነት ያለው ታሪክ ነው።',
            image: plc10,

        },




    ];

    const [topCardIndex, setTopCardIndex] = useState(0);
    const ProductLifeCycleMap = currentLanguage === 'English' ? ProductLifeCycleMapEN: ProductLifeCycleMapAM;
    const header_title = currentLanguage === 'English' ? 'Our Product Development Strategy' : 'የውጤታም ሥራ ስትራቴጂያችን';
    const handleArrowClick = (direction) => {
        const newIndex = direction === 'left' ?
            topCardIndex > 0 ? topCardIndex - 1 : 0 :
            topCardIndex < ProductLifeCycleMap.length - 1 ? topCardIndex + 1 : ProductLifeCycleMap.length - 1;
        setTopCardIndex(newIndex);
    };

    const topCard = ProductLifeCycleMap[topCardIndex]; // Get the top card based on topCardIndex
   
    const { ref: pCycleRef, inView: pCycleInView } = useInView();

    const [pCycleAnimationCompleted, setPCycleAnimationCompleted] = useState(false);

    const pCycleControls = useAnimation();

    useEffect(() => {
        if (pCycleInView && !pCycleAnimationCompleted) {

            pCycleControls.start({

                opacity: 1,
                y: 0,
                x: [0, -10, 10, -10, 10, 0],

                transition: { delay: 0.5 }
            });
            setPCycleAnimationCompleted(true);
        } else {
            if (pCycleInView && pCycleAnimationCompleted) {
                pCycleControls.start({ opacity: 1, y: 0, x: 0 });
            } else {
                pCycleControls.start({ opacity: 0, y: 50, x: 0 });
            }
        }
    }, [pCycleControls, pCycleInView]);


    return (
        <motion.div 
            ref={pCycleRef}
            initial={{ opacity: 0, y: 100 }}
            animate={pCycleControls}
            custom={1}
            className='product-lifecycle-section'
        >
            <h2 className='sub-header'>{header_title}</h2>
            <div className='product-lifecycle-container'>


                <div style={{
                    position: 'relative',
                   
                }}
                >



                    <Arrows handleArrowClick={handleArrowClick} />

                    {ProductLifeCycleMap.map((cycle, index) => (
                        <StackedCard
                            cycle={cycle}
                            index={index}
                            topCardIndex={topCardIndex}
                            key={index}
                        />
                    ))}
                </div>
                <div style={{
                    padding: '20px', 
                    // marginLeft: '64px', position: 'relative', top: '32px',
                   
                }} className='lifecycle-content'>
                    <h2>{topCard.id} {topCard.type}</h2>
                    <div className='lifecycle-image-container'>
                    <img src={topCard.image} alt='Tech-savvy Habesha character, 13 years old, dressed in a Pixar-style outfit with a yellow jumper and modern black skinny jeans, accessorized with oversized cyan-colored eyeglasses.'
></img>
                    </div>
                    <p>{topCard.functions}</p>
                   
                    
                </div>
            </div>

        </motion.div>

    );
};


export default ProductLifeCycle;
