import React, { useState, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';
import {  useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';

import './business-niche.css';


const BusinessNicheSection = ({handleCTAClick}) => {
  const { currentLanguage, setPage } = useAppContext();
  const businessNiches = [
    'Website Design',
    'App Design',
    'Brand Design',
    'Office Software Solutions',
    'AI Powered Solutions',
  ];
  const businessNichesAmharic = [
    'ዌብሳይት ዲዛይን',
    'መተግበሪያ ዲዛይን',
    'ብራንድ ዲዛይን',
    'የቢሮ ሶፍትዌሮች',
    'AI መርህ መፍትሄዎች',
  ];

  // const backgroundColors = ['#FFD700', '#474554', '#87CEEB', '#8B008B', '#FFD8B2'];
  const backgroundColors = ['#FFD700', '#aaffaa', '#ffffaa', '#ffaaaa', '#7ec8e3'];





  // const [currentText, setCurrentText] = useState('');

  const [displayedIndex, setDisplayedIndex] = useState(0);
 
  const [fillPercentage, setFillPercentage] = useState(20);
  const [gradientColor, setGradientColor] = useState('radial-gradient(circle at center, #7be4b1, #C7E6D7)');

  useEffect(() => {
    const fillInterval = setInterval(() => {
      // Update fill percentage
      setFillPercentage((prevPercentage) =>
        prevPercentage < 100 ? prevPercentage + 20 : 20
      );
      const gradientColor = 'radial-gradient(circle at center, #7be4b1, #C7E6D7)';


      setGradientColor(gradientColor);
    }, 3000);

    // Cleanup interval
    return () => clearInterval(fillInterval);
  }, [fillPercentage]);

  useEffect(() => {
    const businessNicheInterval = setInterval(() => {
      setDisplayedIndex((prevIndex) =>
        prevIndex < businessNiches.length - 1 ? prevIndex + 1 : 0
      );
    }, 3000);

    return () => clearInterval(businessNicheInterval);
  }, [displayedIndex, businessNiches.length]);


  const CtaButton = () => {
    const labelButton = currentLanguage === 'English' ? 'Get In Touch' : 'ያግኙን';
    return (
        <button className="cta-button-home" onClick={handleCTAClick}
       >
           {labelButton}
           </button>
    )
}
const [smallScreen, setSmallScreen] = useState(false);
useEffect(() => {
  if (window.innerWidth < 600) {
    setSmallScreen(true);
  } else {
    setSmallScreen(false);
  }
}, []);


  return (

    <>
    {currentLanguage == 'English' && (
    <div className="business-niche-section">
         <div className='cta-container'
         style={{ 
           
        overflow: 'hidden',
     }}
         >
            <h2 className='sub-header'
            style={{
              width:'100%', 
              color:'black',
              
           
            
            
            textTransform:'uppercase',
           }}>
                {/* Our software and technology solutions are tailored to your business needs. */}
                <span >Software and technology solutions</span> tailored to your business needs.
                </h2>
            <CtaButton />
          

        </div>
      <div className="niche-container"
      style={{overflow:'hidden'}}>
       
        {businessNiches.map((niche, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              
            //   transform: 'translate(-50%, -50%) translateY(0)',
              backgroundColor: backgroundColors[index],
              color: '#222',
              letterSpacing: '5px',
              

            }}
            className={`niche-item ${index === displayedIndex ? 'active' : ''} ${index !== displayedIndex && index !== (displayedIndex + 1) ? 'leaving' : ''}`}
          >
            {niche}
          </div>

        ))}

          <div

          className="landing-allday">

          <TypeAnimation
            sequence={[
              'Software and technology solutions.',
              4000,          
              'All Day.',
              2000,
              'All Night.',
              2000,
              'All Year!',
              2000,
              'Change . Impact . Growth .',
              4000,
            
             
             

            ]}
            wrapper="span"
            speed={50}
            style={{ display: 'inline-block', }}
            className='landing-footnote'

            repeat={Infinity}
          />

        </div>
        <div style={{position:'absolute',bottom:'-30px',left:'-25px',
    height:'50px',width:'50px',borderRadius:'50%',
    backgroundColor:backgroundColors[displayedIndex],
    transition:'all 1s ease'}}/>    
      <div style={{
        position:'absolute',top:'-30px',right:'-25px',
    height:'50px',width:'50px',borderRadius:'50%',
    backgroundColor:backgroundColors[displayedIndex],
    transition:'all 1s ease'
    }}/>   

      


        

      </div>
    


      
       
      </div>

    )}
    {currentLanguage == 'አማርኛ' && (
       <div className="business-niche-section">
       <div className='cta-container'
       style={{ 
         
      overflow: 'hidden',
   }}
       >
          <h2 className='sub-header'
          style={{
            width:'100%', 
            color:'black',
            
         
          
          
          textTransform:'uppercase',
         }}>
              {/* Our software and technology solutions are tailored to your business needs. */}
              <span >ቢዝነስዎን የሚመጥኑ የሶፍትዌርና ቴክኖሎጂ </span> መፍትሄዎች 
              </h2>
          <CtaButton />
        

      </div>
    <div className="niche-container"
    style={{overflow:'hidden'}}>
     
      {businessNichesAmharic.map((niche, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            
          //   transform: 'translate(-50%, -50%) translateY(0)',
            backgroundColor: backgroundColors[index],
            color: '#222',
            letterSpacing: '5px',
            

          }}
          className={`niche-item ${index === displayedIndex ? 'active' : ''} ${index !== displayedIndex && index !== (displayedIndex + 1) ? 'leaving' : ''}`}
        >
          {niche}
        </div>

      ))}

        <div

        className="landing-allday">

        <TypeAnimation
          sequence={[
            'ሶፍትዌርና ቴክኖሎጂ ሥራዎች',
            4000,          
            'ሙሉ ቀን',
            2000,
            'ሙሉ ሌሊት',
            2000,
            'ሙሉ ዓመት',
            2000,
            'ለውጥ ፤ ተፅዕኖ ፤ አድገት ',
            4000,
          
           
           

          ]}
          wrapper="span"
          speed={50}
          style={{ display: 'inline-block', }}
          className='landing-footnote'

          repeat={Infinity}
        />

      </div>
      <div style={{position:'absolute',bottom:'-30px',left:'-25px',
  height:'50px',width:'50px',borderRadius:'50%',
  backgroundColor:backgroundColors[displayedIndex],
  transition:'all 1s ease'}}/>    
    <div style={{
      position:'absolute',top:'-30px',right:'-25px',
  height:'50px',width:'50px',borderRadius:'50%',
  backgroundColor:backgroundColors[displayedIndex],
  transition:'all 1s ease'
  }}/>   

    


      

    </div>
  


    
     
    </div>
    )}
    </>
    



      );
};

export default BusinessNicheSection;
