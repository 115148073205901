import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import './AnimatedCardView.css';

const AnimatedCardView = () => {
  const { currentLanguage, setPage } = useAppContext();


  const contentMapEnglish = [
    {
      id: 0,
      title: 'Web Design',
      subtitle: 'Transform your online presence with responsive, brand-infused websites.',
      body: ['Tailored to Ethiopia\'s local market.', 'Clear representation.', 'Custom online stores.', 'Ongoing support.', 'And more.'],
      foot: 'E-commerce | Business | Education | Social | Technology'
    },

    {
      id: 1,
      title: 'App Design',
      subtitle: 'Transform smartphone engagement with captivating designs and reliable experiences.',
      body: ['Trustworthy stores', 'Engaging graphics', 'Continuous support', 'And more.'],
      foot: 'E-commerce | Business | Games | Education | Social'
    }

    ,
    {
      id: 2,
      title: 'Brand Design',
      subtitle: 'Develop, optimize, and contextualize your brand for a competitive edge.',
      body: ['Understand your business deeply.', 'Craft localized and unique brand identities.', 'Ensure cohesion across platforms.', 'Create memorable logos.'],
      foot: 'Brand | Promotion | Logo | Content Creation'
    }
    ,
    {
      id: 3,
      title: 'AI Powered Advancements',
      subtitle: 'Consulting for AI-assisted business approaches, boosting efficiency and innovation.',
      body: [
        'Minimize task overloads, enhance employee satisfaction.',
        'Utilize AI for insightful data analytics.',
        'Streamline processes with AI-driven automation.'
      ],
      foot: 'Data Analytics | Automation | AI Consulting'
    },

    {
      id: 4,
      title: 'Office Software Solutions',
      subtitle: 'Stay ahead and organized with our tailored solutions.',
      body: [
        'Enhance office efficiency.',
        'Automate routine tasks for more impactful work.',
        'Tailored software for specific business needs.',
        'Increase customer/client satisfaction.'
      ],
      foot: 'Automation | Customized Software | ERP | Consulting'
    }

  ];
  const contentMapAmharic = [
    {
      id: 0,
      title: 'ዌብሳይት ዲዛይን',
      foot: 'ኢ-ኮሜርስ | ንግድ | ትምህርት | ማህበራዊ | ቴክኖሎጂ',

      subtitle: 'ኢንተርኔት ላይ ያለዎትን ሥራ በፈጣንና ልዩ ዌብሳይቶች ያቀላጥፉ፡፡',
      body: ['ለኢትዮጵያ ገበያ የተመቸ', 'የተለየ አቅርቦት', 'ብጁ የኢንተርኔት መደብሮች', 'ተከታታይ ድጋፍ', 'ሌሎችም'],
      foot: 'ኢ-ኮሜርስ | ንግድ | ትምህርት | ማህበራዊ | ቴክኖሎጂ'
    },

    {
      id: 1,
      title: 'መተግበሪያ ዲዛይን',
      subtitle: 'በማራኪ ዲዛይኖችና አስተማማኝ የተጠቃሚ ልምዶች የአገልጎሎትዎን የስማርት ስልክ ተደራሽነት ይጨምሩ፡፡',
      body: ['ታማኝ መደብሮች', 'ማራኪ ግራፊክስ ዲዛይኖች', 'ተከታታይ ድጋፍ', 'ሌሎችም'],
      foot: 'ኢ-ኮሜርስ | ንግድ | ጨዋታዎች | ትምህርት | ማህበራዊ'
    }

    ,
    {
      id: 2,
      title: 'ብራንድ ዲዛይን',
      subtitle: 'የአገልግሎትዎን ብራንድ ለተሻለ የገበያ ተፎካካሪነት በሚያበቃዎ መልኩ ይመሥርቱ፤ ያሻሽሉ፡፡',
      body: ['ንግድዎን በጥልቀት ይረዱ', 'አካባቢና ሥራዎን ያገናዘቡ የድርጅት መለያዎችን ይመስርቱ', 'ለተለያዩ ፕላትፎርሞች የተናበበ ዲዛይን', 'የማይረሡ የድርጅት ዓርማዎች'],
      foot: 'ብራንድ | ማስታወቂያቅ | አርማ | ማህበራዊ ይዘት ፈጠራ'
    }
    ,
    {
      id: 3,
      title: 'AI መርህ መፍትሄዎች',
      subtitle: 'AI መርህ ሥራ ማማክር ፤ የሥራ ቅልጥፍና እና ፈጠራ፡፡',
      body: [
        'የሥራ ጫናዎችን ይቀንሱ፣ የሰራተኛ እርካታን ያሳድጉ',
        'AI መርህ የዳታ ትንታኔ ለተሻለ የቢዝነስ ግንዛቤ',
        'የድርጅት የሥራ ሂደትዎን በአውቶሜሽን ያቀላጥፉ'
      ],
      foot: 'ዳታ ትንታኔ | አውቶሜሽን | AI ኮንሳልታንሲ'
    },

    {
      id: 4,
      title: 'የቢሮ ሶፍትዌር መፍትሄዎች',
      subtitle: 'ድርጅትዎን አማክለው በሚሠሩ ሶፍትዌሮቻችን ክፉክክርዎ አንድ እርምጃ ቀድመው ይገኙ፡፡',
      body: [
        'የቢሮ ቅልጥፍናን ያሳድጉ',
        'ተደጋጋሚ ሥራዎችን በሶፍትዌር በመደገፍ ተጽዕኖ ፈጣሪ ሥራዎችን ይሥሩ',
        'ቢዝነሥዎን ያማከሉ የሶፍትዌር ሥራዎች',
        'የደምበኛዎችዎን የአገልግሎት እርካታ ይጨምሩ'
      ],
      foot: 'አውቶሜሽን | ሶፍትዌር | የድርጅት የሥራ ሃይል ማቀጃ| ማማክር'
    }

  ];


  const [currentIndex, setCurrentIndex] = useState(0);
  const [titleHeight, setTitleHeight] = useState('25%'); // Initial height
  const [titleBorderRadius, setTitleBorderRadius] = useState('10%'); // Initial border radius
  const [fontSize, setFontSize] = useState('16px'); // Initial font size


  // const backgroundColors = ['#FFD700', '#474554', '#87CEEB', '#8B008B', '#FFD8B2'];
  const backgroundColors = ['#ffffaa', '#aaffaa', '#FFD700', '#ffaaaa', '#7ec8e3'];
  const backgroundColorNames = ['cream', 'green', 'yellow', 'magenta', 'blue'];
  const intervalRef = useRef(null);
  const [mouseIndex, setMouseIndex] = useState(null);
  const [mouseOverCircle, setMouseOverCircle] = useState(false);

  const [ref, inView] = useInView();
  const header_title = currentLanguage == 'English' ? 'Services We Give' : 'የምንሠጣቸው አገልግሎቶች'
  const contentMap = currentLanguage == 'English' ? contentMapEnglish : contentMapAmharic

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      // setCurrentIndex((prevIndex) => (prevIndex + 1) % contentMap.length);
      inView && setCurrentIndex((prevIndex) => (prevIndex + 1) % contentMap.length);
    }, 2000);

    return () => clearInterval(intervalRef.current);
  }, [inView, contentMap.length]);

  const [mouseInCardView, setMouseInCardView] = useState(false);

  const handleMouseEnter = () => {
    setMouseInCardView(true);
    clearInterval(intervalRef.current);

  };

  const handleMouseLeave = () => {
    setMouseInCardView(false);
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentMap.length);
    }, 2000);
  };

  useEffect(() => {
    const cardContainer = document.querySelector('.card');


    // Calculate dynamic values for height and border radius based on the card container's height
    let minHeight = 0;
    let maxHeight = 0
    if (window.innerWidth <= 700) {
      minHeight = 0.1 * cardContainer.offsetHeight / 1.3;
      maxHeight = 0.5 * cardContainer.offsetHeight / 1.3;
    }
    else {
      minHeight = 0.1 * cardContainer.offsetHeight;
      maxHeight = 0.5 * cardContainer.offsetHeight;

    }
    const heightRange = maxHeight - minHeight;

    const minBorderRadius = 0.1 * minHeight;
    const maxBorderRadius = 0.5 * maxHeight;
    const borderRadiusRange = maxBorderRadius - minBorderRadius;

    // Calculate percentage based on the currentIndex
    const percentage = ((currentIndex + 1) / contentMap.length) * 100;

    // Update state with dynamic values
    setTitleHeight(`${minHeight + (percentage / 100) * heightRange}px`);
    setTitleBorderRadius(`${minBorderRadius + (percentage / 100) * borderRadiusRange}px`);

    // Update state with dynamic font size
    setFontSize(`${16 + (percentage / 100) * 12}px`); // Initial size of 16px, range from 16px to 26px
  }, [currentIndex, contentMap]);

  const balloonEffect = {

    // textShadow: `0 0 5px rgba(0, 0, 0, 0.3)`, // Subtle text shadow for depth
    // transition: 'transform 0.3s ease', 

  };


  const cardTitleStyle = {
    height: titleHeight,
    borderRadius: titleBorderRadius,
    transition: 'height 0.5s ease, border-radius 0.5s ease', // Transition for smooth changes
  };

  const progressCircle = {
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'space-between',
    // width: '15%', 
    cursor: 'pointer',
  };

  const { ref: servicesRef, inView: servicesInView } = useInView();
  const servicesControls = useAnimation();
  const [servicesAnimationCompleted, setServicesAnimationCompleted] = useState(false);
  useEffect(() => {
    if (servicesInView && !servicesAnimationCompleted) {

      servicesControls.start({

        opacity: 1,
        y: 0,
        x: [0, -10, 10, -10, 10, 0],

        transition: { delay: 0.5 }
      });
      setServicesAnimationCompleted(true);
    } else {
      if (servicesInView && servicesAnimationCompleted) {
        servicesControls.start({ opacity: 1, y: 0, x: 0 });
      } else {
        servicesControls.start({ opacity: 0, y: 50, x: 0 });
      }
    }
  }, [servicesControls, servicesInView]);


  

  return (
    <motion.div ref={servicesRef}
      initial={{ opacity: 0, y: 100 }}
      animate={servicesControls}

      custom={1}
      className='animated-card-section'>
      
      
      <h2 className='sub-header'>{header_title}</h2>
      <div className="animated-card-container" ref={ref}>

        <div style={{
          display: 'flex', flexDirection: 'column',
          height: '100%',
          margin: 'auto', justifyContent: 'center',
          alignItems: 'center',
        }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div className="card"  >
            <div style={{
              position: 'relative', display: 'flex',
              flexDirection: 'column',




            }}>


              <div className="card-title" style={{
                ...cardTitleStyle, fontSize: fontSize, backgroundColor:
                  mouseInCardView ? '#c7e6d7' : '#474545',
                // color: backgroundColors[currentIndex] == '#474554' || backgroundColors[currentIndex] == '#8B008B' ? '#F5F5F5' : '#474554',
                color: mouseInCardView ? '#474545' : backgroundColors[currentIndex]
              }}>
                <span style={balloonEffect}
                key={`title-${contentMap[currentIndex].id}`}>{contentMap[currentIndex].title}</span>
              </div>
              <div className="card-foot"
                style={{
                  color: backgroundColors[currentIndex],
                  padding: '5px', backgroundColor: '#474545',
                }}>
                <span key={`foot-${contentMap[currentIndex].id}`}>{contentMap[currentIndex].foot}</span>
              </div>
            </div>
            <div className="card-content" >
              <div className="card-subtitle"
                style={{
                  color: '#474554',
                  // backgroundColor: '#474554', 
                  borderRadius: '4px 8px',
                }}>
                <span key={`subtitle-${contentMap[currentIndex].id}`}>{contentMap[currentIndex].subtitle}</span>

              </div>
              <div className="card-body">
                <ul key={`list-${currentIndex}`}>
                  {contentMap[currentIndex].body.map((paragraph, index) => (
                    <li key={`item-${index}`}>
                      <span className={`custom-marker ${backgroundColorNames[currentIndex]}`}>
                        {paragraph}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>


            </div>
            <div style={{
              position: 'absolute', top: '0', right: '-25px',
              width: '50px', height: '50px', borderRadius: '50%',
              backgroundColor: mouseInCardView ? '#c7e6d7' : '#474545'
            }}></div>
          </div>
          {mouseOverCircle && (
            <div style={{
              position: 'absolute', bottom: '10%', left: '50%',
              transform: 'translateX(-50%)', fontSize: '14px'
            }}
            className='mouse-over-circle'>
              <p>{contentMap[mouseIndex].title}</p>

            </div>
          )}

          <div className="progress-circle" style={progressCircle}>
            {contentMap.map((_, index) => (
              <div
                key={index}
                style={{

                  borderRadius: '50%',

                  backgroundColor: currentIndex === index ? backgroundColors[currentIndex] : '#888',
                  transition: 'background-color 0.3s ease',
                  // border: currentIndex === index ? '1px solid #474545' : '',
                  boxShadow: currentIndex === index ? '0 6px 12px rgba(0, 0, 0, 0.5)' : '',
                }}
                onClick={() => setCurrentIndex(index)}

                onMouseEnter={() => {
                  setMouseOverCircle(true);
                  setMouseIndex(index);
                }}
                onMouseLeave={() => setMouseOverCircle(false)}
              >

              </div>
            ))}
          </div>
        </div>


      </div>
      
      

    </motion.div>
  );
};

export default AnimatedCardView;
