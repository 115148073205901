import aff1 from '../assets/images/affiliations/aau.png'
import aff2 from '../assets/images/affiliations/fh-joann.png'
import aff3 from '../assets/images/affiliations/aacityadministration.png'
import aff4 from '../assets/images/affiliations/abmikro.jpeg'
import aff5 from '../assets/images/affiliations/nxp.png'
import aff6 from '../assets/images/affiliations/alx.png'

import { useInView } from 'react-intersection-observer';
import { useState, useEffect } from 'react'
import { useAnimation,motion } from 'framer-motion'
import { useAppContext } from '../AppContext';

function Affiliations () {
  const { currentLanguage, setPage } = useAppContext();
  const [newClass,setNewClass] = useState(false);
  
  const [refAffiliations, inView] = useInView();
  useEffect(() => {
     inView?setNewClass(true):setNewClass(false);
  }, [inView]);

  const {ref:affiliationsRef, inView: affiliationsInView} = useInView();
   
  const [affiliationsAnimationCompleted, setAffiliationsAnimationCompleted] = useState(false);

  const affiliationsControls = useAnimation();

  useEffect(() => {
      if (affiliationsInView && !affiliationsAnimationCompleted) {
      
          affiliationsControls.start({
  
              opacity: 1,
              y: 0,
              x: [0, -10, 10, -10, 10, 0],
             
              transition: { delay:  0.5 }
          });
          setAffiliationsAnimationCompleted(true);
      } else {
          if (affiliationsInView && affiliationsAnimationCompleted) {
              affiliationsControls.start({ opacity: 1, y: 0,x: 0 });
          } else {
              affiliationsControls.start({ opacity: 0, y: 50,x: 0 });
          }
      }
  }, [affiliationsControls, affiliationsInView]);

    const header_title = currentLanguage==='English' ? 'Affiliations' : 'ግንኙነቶች'
    return (
        <motion.div className='affiliations' ref={affiliationsRef}
       
        initial={{ opacity: 0, y: 100 }}
    animate={affiliationsControls}
    custom={1}
    style={{margin:'auto'}}
   >
            <h2 className='sub-header'>{header_title}</h2>
           
            <div ref={refAffiliations}
             className= {`${newClass? 'moving' : ''}`}>
              <img src={aff1} alt='aau' className='Addis Ababa University logo'/>
              <img src={aff2} alt='fh-joann' className='FH Joanneum University of Applied Sciences logo'/>
              <img src={aff3} alt='aacity' className='Addis Ababa City Administration logo'/>
              <img src={aff4} alt='abmikro' className='Abmikro technology company logo'/>
              <img src={aff5} alt='nxp' className='NXP Semiconductors company logo'/>
              <img src={aff6} alt='alx' className='ALX Africa logo'/>
            </div>         
           

          
          </motion.div>
    )
}

export default Affiliations;