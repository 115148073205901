import React from 'react';
import logo from '../assets/images/logo/company_logo.png';
import { useAppContext } from '../AppContext';

const AboutInvite = () => {
    const { currentLanguage, setPage } = useAppContext();
    return(
       
            <div className='turbo-impact'>  
               
                <img
                    src={logo}  // Replace 'logo' with the actual path to your logo
                    alt="Your Logo Alt Text"
                    style={{ objectFit: 'cover', 
                    borderRadius:'40px 20px'
                 }}
                />  
                <div style={{color:'#000', fontWeight:'bold',lineHeight:'1.2',backgroundColor:'#ffd230'}}>brosfe | Tech Innovation Beyond Boundaries</div>
                {currentLanguage==='English' && (
                    <p className='cfa-turbo'>
                    Let's <span className='highlight-turbo'>Turbo Charge</span> Your Business Together!
                </p>

                )}

                {currentLanguage === 'አማርኛ' && (
                    <p className='cfa-turbo'>
                    የዲጂታል አቅርቦትዎን <span className='highlight-turbo'>በጋር ከፍ </span>እናድርገው!
                </p>

                )}
                
                
            </div>
    
       

    )

   

};

export default AboutInvite;
