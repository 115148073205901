// LanguageSelector.js
import React from 'react';
import { useAppContext } from '../AppContext';
import languageIcon from '../assets/images/icons/language-icon.png';
import amharicLanguage from '../assets/images/navigation/AMH.png';
import englishLanguage from '../assets/images/navigation/ENG.png';

import './LanguageSelector.css'

const LanguageSelector = () => {
  const { currentLanguage,handleLanguageChange } = useAppContext();
  return (
    <div className="language">
      <a href="#" onClick={handleLanguageChange} >
        {/* <img src={languageIcon} className="lang-icon" alt="lang-icon" /> */}
        {currentLanguage === 'English' ? (
          <img src={amharicLanguage} className="lang-icon" alt="lang-icon" />
        ) : (
          <img src={englishLanguage} className="lang-icon" alt="lang-icon" />
        )}
        
        {/* <p >{currentLanguage === 'English' ? 'አማ' : 'ENG'}</p> */}
      </a>
    </div>
  );
}

export default LanguageSelector;
