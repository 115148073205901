import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import { useInView } from 'react-intersection-observer';

import AnimatedCardView from './AnimatedCardView';
import ProductLifeCycle from './ProductLifeCycle';
import ScreenSize from './ScreenSize';

import BusinessNicheSection from './BusinessNicheSection';
import ServicesDemo from './ServicesDemo';
import TechTestimonies from './TechTestimonies';
import Intros from './Intros';
import Footer from './Footer';

import promoFM1 from '../assets/images/about/promotional/promoFM2.png';
import promoFM1_portrait from '../assets/images/about/promotional/promoFM2_portrait.png';

import promoMM1 from '../assets/images/about/promotional/promoMM2.png';
import promoMM1_portrait from '../assets/images/about/promotional/promoMM2_portrait.png';

import promoMM1_AM from '../assets/images/about/promotional/promoMM2_AM.png';
import promoMM1_portrait_AM from '../assets/images/about/promotional/promoMM2_portrait_AM.png';
import './Home.css'

import Affiliations from './Affiliations';
import InputForm from './InputForm';
import Transition from './Transition';





function Home() {

 
  const { currentLanguage, setPage } = useAppContext();
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 430;


  const [refPitch, inView] = useInView();

  const homeInputFormRef = useRef();

  const handleCTAClick = () => {

    if (homeInputFormRef && homeInputFormRef.current) {
      // Scroll to the element

      homeInputFormRef.current.scrollIntoView({
        behavior: 'smooth', // Optional: Add smooth scrolling effect
        block: 'start',     // Optional: Align to the top of the viewport
      });
    }
  };




  const [pitchInView, setPitchInView] = useState(false);
  useEffect(() => {
    setPage('Home');

    inView ? setPitchInView(true) : setPitchInView(false);

  }, [inView, pitchInView]);




  const [showBottom, setShowBottom] = useState(false);


  useEffect(() => {
    setShowBottom(false);

  }, []);

  const DroppingBubble = ({ top, left, backgroundColor }) => {

    const bubbleRef = useRef();

    useEffect(() => {
      const handleScroll = () => {
        const bubble = bubbleRef.current;

        if (bubble) {
          const rect = bubble.getBoundingClientRect();
          const windowHeight = window.innerHeight;

          // Adjust the threshold for when the bubble starts vanishing
          const vanishThreshold = windowHeight * 1;

          if (rect.bottom < vanishThreshold) {
            bubble.style.transition = 'opacity 0.5s'; // Add a smooth transition effect
            bubble.style.opacity = '0';
          } else {
            bubble.style.transition = 'none'; // Remove transition when not at the bottom
            bubble.style.opacity = '1';
          }
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return <div className="dropping-bubble" ref={bubbleRef} style={{ top, left, backgroundColor }}></div>;


  };


  const backgroundColors = ['#FFD700', '#aaffaa', '#ffffaa', '#ffaaaa', '#7ec8e3'];
  const Bubbles = () => {
    const [bubblePositions, setBubblePositions] = useState([
      { top: '60%', left: '50%' },
      { top: '30%', left: '50%' },

      { top: '60%', left: '70%' },
      { top: '30%', left: '90%' },
      { top: '60%', left: '90%' },


    ]);

    const [scaleFactor, setScaleFactor] = useState(1);
    const [reachedCenter, setReachedCenter] = useState(false);
    const updateBubblePositions = () => {
      // Move bubbles to the center of the screen
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight;

      return Promise.resolve(setBubblePositions(bubblePositions.map(() => ({ top: centerY + 'px', left: centerX + 'px' }))))
        .then(() => {
          // Introduce a timeout for setReachedCenter
          setTimeout(() => setReachedCenter(true), 1000); // Adjust the timeout duration as needed
        });
    };

    useEffect(() => {
      const handleScroll = () => {
        const scrollThreshold = window.innerHeight / 7;

        if (window.scrollY > scrollThreshold) {
          const scaleIncrement = 0.005; // Adjust the increment as needed
          const newScaleFactor = Math.min(1 + (window.scrollY - scrollThreshold) * scaleIncrement, 2);

          setScaleFactor(newScaleFactor);

          // Move bubbles to the center of the screen
          const centerX = window.innerWidth / 2;
          const centerY = window.innerHeight / 2;

          updateBubblePositions();
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [bubblePositions]);



   
   

    return (
      <>
        {bubblePositions.map((position, index) => (
          <div
            key={index}
            className={`bubble-main ${index % 2 === 0 ? 'big-bubble' : index % 3 === 1 ? 'small-bubble' : 'medium-bubble'}`}
            style={{
              ...position,
              transform: `scale(${scaleFactor})`,
              opacity: reachedCenter ? 0 : 1,
              backgroundColor: backgroundColors[index % backgroundColors.length]
            }}
          ></div>
        ))}
        <>
          <div className='bubble-mini'>
            <div style={{
              width: '20px', height: '20px', position: 'absolute',
              top: '25%', right: '15%', borderRadius: '50%',
              backgroundColor: backgroundColors[0]
            }} />
            <div style={{
              width: '25px', height: '25px', position: 'absolute',
              top: '15%', right: '75%', borderRadius: '50%',
              backgroundColor: backgroundColors[1]
            }} />
            <div style={{
              width: '30px', height: '30px', position: 'absolute',
              top: '45%', right: '45%', borderRadius: '50%',
              backgroundColor: backgroundColors[2]
            }} />


          </div></>
      </>
    );
  };

  const formattedCompanyName = (inHeader) => {
    
    const companyName = currentLanguage === 'አማርኛ' ? 'ብሮስፌ' : 'brosfe';
    const nameLength = companyName.length;

    return (
      <span className='company-name'>
        {companyName.split(' ').map((word, wordIndex) => (
          inHeader ? (
            <span key={wordIndex}>
              {word.split('').map((char, charIndex) => (
                <span
                  key={charIndex}
                  style={
                    (wordIndex === 0) // Check if it's 'Puzzle' and apply color
                      ? { color: '#000000', textShadow: '0 4px 12px rgba(235, 177, 76, 0.1)' }
                      : { color: '#000000' } // Default color for 'Designs'
                  }
                >
                  {char}
                </span>
              ))}
              {wordIndex === 0 ? ' ' : ''} {/* Add space between words */}
            </span>
          ) :
            (
              <span key={wordIndex}>
                {word.split('').map((char, charIndex) => (
                  <span
                    key={charIndex}
                    style={
                      (wordIndex === 0 && charIndex !== nameLength - 1) // Check if it's 'Puzzle' and apply color
                        ? { color: '#c0c0c0' }
                        : { color: '#f6f6f6' } // Default color for 'Designs'
                    }
                  >
                    {char}
                  </span>
                ))}
                {wordIndex === 0 ? ' ' : ''} {/* Add space between words */}
              </span>
            )
        )
        )
        }
      </span>
    );
  };

  const pageSectionColors = ['#eceee0', '#ddd'];


  return (

    <div className="main-container">

      {currentLanguage == 'English' && (


        <div className='sub-container-home'
        lang="en">
          {/* <ScreenSize /> */}

          {/* <NavigationBar /> */}
          <BusinessNicheSection handleCTAClick={handleCTAClick} />
          <div className="landing-page">
            <Bubbles />

          </div>
          <div className='pitchHome' ref={refPitch}>
            <div style={{ zIndex: 1 }}
              className={`${pitchInView ? 'pitchDown' : 'pitchUp'}`}
            >

              <h1 >Let's <span style={{ color: '#695306', textShadow: '8px 4px 12px rgba(0, 0, 0, 1)' }}>TURBO CHARGE</span> your digital presence together. </h1>

              <h3><span className='company-name'
                style={{ letterSpacing: '4px', textShadow: '4px 4px 12px rgba(255, 216, 178, 0.5)' }}>{formattedCompanyName(true)}</span> <span style={{
                  color: '#474554', letterSpacing: '5px',

                }}
                >| TECH INNOVATION BEYOND BOUNDARIES</span></h3>


            </div>

            <div className={`layered-pitch ${pitchInView ? 'pitchUp' : 'pitchDown'}`}>

              {isMobile ? (
                <>
                 <img src={promoFM1_portrait} alt="Young Ethiopian woman dressed in yellow shirt and white jeans gazing standing and holding laptop and gazing into the camera with an inviting smile." />
                 <img src={promoMM1_portrait} alt="Young Ethiopian man portrait in his 20s, oil painting representing change, impact and growth." />
                 </>
              ) : (
                <>
                 <img src={promoFM1} alt="Young Ethiopian woman dressed in yellow shirt and white jeans gazing standing and holding laptop and gazing into the camera with an inviting smile." />
                <img src={promoMM1} alt="Young Ethiopian man portrait in his 20s, oil painting representing change, impact and growth."  /></>
              )}

             
            </div>

          </div>
          <div style={{ backgroundColor: pageSectionColors[0 % pageSectionColors.length] }}>
            <AnimatedCardView />
          </div>

          <div className='services-demo'
            style={{ backgroundColor: pageSectionColors[1 % pageSectionColors.length] }}>
            <ServicesDemo />

          </div>
          <div style={{ backgroundColor: pageSectionColors[2 % pageSectionColors.length] }}>
            <ProductLifeCycle />
          </div>

          <div style={{ backgroundColor: pageSectionColors[3 % pageSectionColors.length] }}>
            <Intros />
          </div>

          <div style={{ backgroundColor: pageSectionColors[4 % pageSectionColors.length] }}>

            <Affiliations />
          </div>
          <div style={{ backgroundColor: pageSectionColors[5 % pageSectionColors.length] }}>
            <TechTestimonies />
          </div>
          <div style={{ backgroundColor: pageSectionColors[6 % pageSectionColors.length] }}
            ref={homeInputFormRef}>

            <InputForm />
          </div>
          <Footer />
        </div>

      )}
      {currentLanguage === 'አማርኛ' && (
        <div className='sub-container-home'
        lang="am">
         
           {/* <ScreenSize /> */}

         
          <BusinessNicheSection handleCTAClick={handleCTAClick} />
          <div className="landing-page">
            <Bubbles />

          </div>
          <div className='pitchHome' ref={refPitch}>
            <div style={{ zIndex: 1 }}
              className={`${pitchInView ? 'pitchDown' : 'pitchUp'}`}
            >

              <h1>የዲጂታል ንግድና አቅርቦትዎን በጋራ <span style={{ color: '#695306', textShadow: '8px 4px 12px rgba(0, 0, 0, 1)' }}>ከፍ እናድርገው </span></h1>

              <h3><span className='company-name'
                style={{ letterSpacing: '4px', textShadow: '4px 4px 12px rgba(255, 216, 178, 0.5)' }}>{formattedCompanyName(true)}</span> <span style={{
                  color: '#474554', letterSpacing: '5px',

                }}
                >| የቴክኖሎጂ መፍትሄዎችን ያለ ወሠን </span></h3>


            </div>

            <div className={`layered-pitch ${pitchInView ? 'pitchUp' : 'pitchDown'}`}
            >

              {isMobile ? (
                <>
                 <img src={promoFM1_portrait} alt="Young Ethiopian woman in her twenties gazing into the future from a modern window." />
                 <img src={promoMM1_portrait_AM} alt="Young Ethiopian woman in her twenties gazing into the future from a modern window." />
                 </>
              ) : (
                <>
                 <img src={promoFM1} alt="Young Ethiopian woman in her twenties gazing into the future from a modern window." />
                <img src={promoMM1_AM} alt="Young Ethiopian woman in her twenties gazing into the future from a modern window." /></>
              )}

             
            </div>

          </div>
          <div style={{ backgroundColor: pageSectionColors[0 % pageSectionColors.length] }}>
            <AnimatedCardView />
          </div>

          <div className='services-demo'
            style={{ backgroundColor: pageSectionColors[1 % pageSectionColors.length] }}>
            <ServicesDemo />

          </div>
          <div style={{ backgroundColor: pageSectionColors[2 % pageSectionColors.length] }}>
            <ProductLifeCycle />
          </div>

          <div style={{ backgroundColor: pageSectionColors[3 % pageSectionColors.length] }}>
            <Intros />
          </div>

          <div style={{ backgroundColor: pageSectionColors[4 % pageSectionColors.length] }}>

            <Affiliations />
          </div>
          <div style={{ backgroundColor: pageSectionColors[5 % pageSectionColors.length] }}>
            <TechTestimonies />
          </div>
          <div style={{ backgroundColor: pageSectionColors[6 % pageSectionColors.length] }}
            ref={homeInputFormRef}>

            <InputForm />
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default Transition(Home);
