
import { useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from '../AppContext';
import './LinkToPortfolios.css';

function LinkToPortfolios({ headerText, listItems, sectionRefs }) {
  const { currentLanguage } = useAppContext();

  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    observer.observe(document.getElementById('seeMoreContainer'));

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleListItemClick = (itemText, sectionRefs) => {
    console.log(`Navigating to ${headerText} -> ${itemText}`);
    // Assuming you have some logic to determine the correct section based on itemText
    const sectionRef = determineSectionRef(itemText, sectionRefs);
    console.log(itemText, sectionRefs)
    console.log('Section Ref:', sectionRef);
    console.log('Section Ref current',sectionRef.current);
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const determineSectionRef = (itemText) => {
    // console.log
    sectionRefs.find((ref) => console.log(ref.name));
    console.log(itemText);
    const sectionRef = sectionRefs.find((ref) => ref.name === itemText);
    return sectionRef || null;
  };



  const ListItem = ({ itemText, onItemClick }) => {

    return <li onClick={() => onItemClick(itemText)}>{itemText}</li>;

  };

  const filteredItems = listItems.filter(item => item !== headerText);
  const seemore = currentLanguage === 'English' ? 'SEE MORE' : 'ደግመው ይመልከቱ'
  return (
    <div className='LinkToPortfolios'>
      
        <div className='LinkToPortfolios-english'>
         
          <div className='arrow-container' id="seeMoreContainer">
            <p>{seemore}</p>
            {/* <div className={`arrow-icon-container ${isInView ? 'animated' : ''}`}>
              <FontAwesomeIcon icon={faArrowRight} className="arrow-icon-in-link" />
            </div> */}
            <div className='arrow-icon-container'>
              <FontAwesomeIcon icon={faArrowRight} className="arrow-icon-in-link" />
            </div>
          </div>

          <div>
            <ul>
              {filteredItems.map((item, index) => (
                <ListItem key={index} itemText={item} onItemClick={() => handleListItemClick(item, sectionRefs)} />
              ))}
            </ul>
          </div>

        </div>

      
      {/* {currentLanguage === 'አማርኛ' && (
        <div className='LinkToPortfolios-amharic'>
         

          <div className="Motto">
            <h1>ይህ የኮምፖነንት አምሳያ ገጽ ነው፡፡</h1>
          </div>
        </div>


      )} */}

    </div>

  );
}

export default LinkToPortfolios;
