import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AppProvider } from './AppContext';
import { AnimatePresence } from 'framer-motion';

import Home from './components/Home';
import Portfolio from './components/Portfolio';
import Pricing from './components/Pricing';
import About from './components/About';
import ContactUs from './components/ContactUs';
import Blog from './components/Blog';
import NavigationBar from './components/NavigationBar';
import Transition from './components/Transition';
import ScrollToTopOnNavigate from './components/ScrollToTopOnNavigate';

import './App.css'; // Make sure to adjust the path based on your project structure

function App() {

 
  return (

    <AnimatePresence mode='concurrent'>
      <Router>
        <AppProvider>
          <ScrollToTopOnNavigate />
          <NavigationBar />
          <Routes >
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<About />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/blog/*" element={<Blog />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </AppProvider>
      </Router>
    </AnimatePresence>

  );
}

export default App;
