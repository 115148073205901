// DynamicList.js
import React, { useState, useEffect, useRef } from 'react';

const DynamicList = ({ title, items, inView }) => {
  const [listItems, setListItems] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const containerRef = useRef();


  useEffect(() => {
    const addListItem = () => {
      setListItems(prevItems => {
        if (currentItemIndex === items.length ) {
          // If it reaches the last item, reset the list
          setCurrentItemIndex(0);
          return [<li key={`currentItem-${currentItemIndex}`}
            >{items[0]}</li>];
       
        } else {
          // Add a new li item
          let nextIndex;
          if(prevItems.length === 0){
            
            nextIndex = currentItemIndex;
          }else{
            nextIndex = currentItemIndex + 1;
          }
          
          setCurrentItemIndex(nextIndex);
          
          return [...prevItems, <li key={`nextItem-${nextIndex}`}
            >{items[nextIndex]}</li>];
        }
      });
    };

    if (inView) {
      const intervalId = setInterval(addListItem, 500);
  

      return () => clearInterval(intervalId);
    }
  }, [items, currentItemIndex, inView]);

  return (
    <div
      ref={containerRef}
      key={`item-${currentItemIndex}`}
      style={{ backgroundColor: '#474554',color:'#f5f5f5', textAlign: 'left', position: 'relative' }}
      className='demo-item-info'
    >
      <h3 style={{position:'absolute',bottom:'10%', right:'5%', fontSize:'14px'}}
      key={`${title}-${currentItemIndex}`}>{title}</h3>
      <ul className='demo-text' style={{ position: 'absolute', zIndex:1}}
      key={`ul-${currentItemIndex}`}>
        {listItems}
      </ul>
     
      
       {/* Yellow lines */}
     
       <div
        style={{
          position: 'absolute',
          top: '90%',
          left: 0,
          right: 0,
          height: 2,
          backgroundColor: '#f5f5f5',
          content: ' ',
          
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '85%',
          left: '70%',
          width: 25, // Adjust the width to control the size of the point of light
          height: 25, // Adjust the height to control the size of the point of light
          borderRadius: '50%', // Create a circular shape
          background: 'radial-gradient(circle, rgba(255, 210, 48, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
          animation: 'moveReflectiveLight 10s linear infinite', // Adjust the duration as needed
        }}
      />
       <div
        style={{
          position: 'absolute',
          top: '87%',
          left: 0,
          width: 15, // Adjust the width to control the size of the point of light
          height: 15, // Adjust the height to control the size of the point of light
          borderRadius: '50%', // Create a circular shape
          background: 'radial-gradient(circle, rgba(255, 255, 200, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
          animation: 'moveReflectiveLight 10s linear infinite', // Adjust the duration as needed
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '95%',
          left: 0,
          right: 0,
          height: 2,
          backgroundColor: '#ffd230',
          content: ' ',
          
        }}
      />
    </div>
  );
};

export default DynamicList;
