import React, { useState, useEffect } from 'react';

import DynamicInputs from './DynamicInputs';
import { useAppContext } from '../AppContext';


const FormStep = () => {
  const { currentLanguage, setPage } = useAppContext();
    const [formVisibility, setFormVisibility] = useState(0);



    const [inputValues, setInputValues] = useState({
        from_name: '',
        from_email: '',
        from_number: '',
        message: '',

    });
    const inputKeys = Object.keys(inputValues);
    const dynamicInputsMapEN = [
        { key: inputKeys[0].trim(), label: 'Name', value: inputValues.from_name },
        { key: inputKeys[1].trim(), label: 'Email', value: inputValues.from_email },
        { key: inputKeys[2].trim(), label: 'PhoneNumber', value: inputValues.from_number },
        { key: inputKeys[3].trim(), label: 'Message', value: inputValues.message },
      ];

      const dynamicInputsMapAM = [
        { key: inputKeys[0].trim(), label: 'ስም', value: inputValues.from_name },
        { key: inputKeys[1].trim(), label: 'ኢሜይል', value: inputValues.from_email },
        { key: inputKeys[2].trim(), label: 'ስልክ ቁጥር', value: inputValues.from_number },
        { key: inputKeys[3].trim(), label: 'መልእክት', value: inputValues.message },
      ];

     const dynamicInputsMap = currentLanguage === 'English' ? dynamicInputsMapEN : dynamicInputsMapAM;

   
 
    const updateInputValues = (key, value) => {
        setInputValues(prevValues => ({ ...prevValues, [key]: value }));
        setValidationError(false);
       
    };

    const [validationError, setValidationError] = useState(false);

    const updateFormVisibilities = (inputKey) => {

        const keysArray = Object.keys(inputValues);


        const index = keysArray.indexOf(inputKey);

      
        if (typeof inputValues[inputKey] === 'string' && inputValues[inputKey].trim() !== '') {
            setValidationError(false);
            setFormVisibility((prevValue) => prevValue + 1);
        }
        else{
            setValidationError(true);
        }

    };

    const handleInputInfos = (inputKey) => {
        return inputValues[inputKey];
    }

    const renderedDynamicInputs = dynamicInputsMap.map((input,index) => (
        <DynamicInputs
          key={input.key}
          index={index}
          inputKey={input.key}
          label={input.label}
          inputValue={input.value}
          updateInputValue={(value) => updateInputValues(input.key, value)}
          updateFormVisibility={(someKey) => updateFormVisibilities(someKey)}
          handleInputInfo={(someKey) => handleInputInfos(someKey)}
          formVisibility={formVisibility}
          style={{ textIndent: 10 }}
          
          
        />
      ));
      

  
    const YShift = -4 * 40;
    const gradientStop = (percentage) => `${percentage}%`;

const CircularGradient = ({ formVisibility }) => {
  return (
    <div
      style={{
       
        border:'1px solid #aaaaff',
        boxShadow: '0 4px 12px rgba(170, 170, 255, 0.5)',
      
        transform: 'translate(-50%, -50%)',
        background: `linear-gradient(
          to top,
          #aaaaff 0%, /* Orange color for the starting point */
          #aaaaff ${gradientStop(formVisibility * 20)}, /* Adjust as needed */
          transparent ${gradientStop(formVisibility * 20)}, /* Transparent for the rest */
          transparent 100%
        )`,
      }}
      className='circular-gradient'
    ></div>
  );
};

   const validationError_msg = currentLanguage === 'English'? 
   'Please complete the above field.':'እባክዎን ከላይ ያለውን የፎርም መስክ ይሙሉ።';

    return (
        <div style={{height:'70px',
        padding:'10px',
        width:'100%'}}
        >


            

                 {renderedDynamicInputs}
         
            
                 <input type="submit" value="Send" className="cta-contact" 
                 style={{ 
                    visibility: formVisibility === 4 ? 'visible' : 'hidden',
                 transform: `translateY(${YShift}px)` ,
                 backgroundColor:'#aaaaff',
                 color:'#474554',
                 borderColor: '#474554',
                }}
                 onMouseOver={(e) => {
                    // Add styles for on hover
                  
                    e.currentTarget.style.border = `2px solid #474554`;
                    e.currentTarget.style.fontWeight = `bold`;
                    e.currentTarget.style.color='#474554';
                   
                    
                  }}
                  onMouseOut={(e) => {
                    // Add styles for on hover
                  
                    e.currentTarget.style.border = `1px solid #474554`;
                    e.currentTarget.style.fontWeight = `normal`;
                    e.currentTarget.style.color='#474554';
                   
                  }}
                  onClick={()=> {
                    setTimeout(() => {
                      setFormVisibility(5);
                      
                    }, 1500);
                   
                  }}

                 />

                 {validationError && <p style={{color:'red',
                position:'absolute',bottom:'10%',left:'22%',
                fontSize:'14px'}}>{validationError_msg}</p>}

                {formVisibility < 5 && (<CircularGradient formVisibility={formVisibility} />)}

            


        </div>

    )

};
export default FormStep;