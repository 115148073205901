import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('English');
  const [currentPage, setCurrentPage] = useState('Home'); // Default page

  const handleLanguageChange = () => {
    setCurrentLanguage((prevLanguage) =>
      prevLanguage === 'English' ? 'አማርኛ' : 'English'
    );
  };
  const setPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <AppContext.Provider value={{
      currentLanguage,
      handleLanguageChange,
      currentPage,
      setPage,
      key: `${currentLanguage}-${currentPage}`,
    }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const  useAppContext = () => {
  return useContext(AppContext);
};
