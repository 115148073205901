
import React, { useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import DynamicList from './DynamicList';

import Transition from './Transition';
import { TypeAnimation } from 'react-type-animation';

import { FaPhone, FaEnvelope, FaLinkedin, FaArrowRight, FaTimes } from 'react-icons/fa';

import ReachYouForm from './ReachYouForm';
import ScreenSize from './ScreenSize';
import logoMini from '../assets/images/logo/company_logo_mini.png';
import downloadIcon from '../assets/images/icons/download.png';



import { useAppContext } from '../AppContext';
import Footer from './Footer';

import './Portfolio.css';

import webPic1 from '../assets/images/works/nayeDesigns.JPG';
import webVid1 from '../assets/videos/portfolio/nayeDesigns.mp4';
import webPic2 from '../assets/images/works/dashboardOSC.JPG';
import webVid2 from '../assets/videos/portfolio/dashboardOSCFS.mp4';
import appPic1 from '../assets/images/apps/roulette-drinking.jpg';
import appPic1_2 from '../assets/images/apps/roulette-intimate.jpg';
import appVid1 from '../assets/videos/portfolio/rouletteApp.mp4';
import iconVisitSite from '../assets/images/icons/arrow-visitSite.png';


import brandLogo1 from '../assets/images/branding/logos/logo_s_1.png';
import brandLogo2 from '../assets/images/branding/logos/logo_s_2.png';
import brandLogo3 from '../assets/images/branding/logos/logo_s_3.png';
import brandLogo4 from '../assets/images/branding/logos/logo_s_4.png';
import brandLogo5 from '../assets/images/branding/logos/logo_s_5.png';
import brandLogo6 from '../assets/images/branding/logos/logo_s_6.png';
import brandLogo7 from '../assets/images/branding/logos/logo_s_7.png';

import brandBc1 from '../assets/images/branding/business_cards/bc_s_1.png';
import brandBc2 from '../assets/images/branding/business_cards/bc_s_2.png';
import brandBc3 from '../assets/images/branding/business_cards/bc_s_3.png';
import brandBc4 from '../assets/images/branding/business_cards/bc_s_4.png';
import brandBc5 from '../assets/images/branding/business_cards/bc_s_5.png';
import brandBc6 from '../assets/images/branding/business_cards/bc_s_6.png';
import brandBc7 from '../assets/images/branding/business_cards/bc_s_7.png';

import brandMm1 from '../assets/images/branding/marketing_materials/mm_s_1.png';
import brandMm2 from '../assets/images/branding/marketing_materials/mm_s_2.png';
import brandMm3 from '../assets/images/branding/marketing_materials/mm_s_3.png';
import brandMm4 from '../assets/images/branding/marketing_materials/mm_s_4.png';
import brandMm5 from '../assets/images/branding/marketing_materials/mm_s_5.png';
import brandMm6 from '../assets/images/branding/marketing_materials/mm_s_6.png';
import brandMm7 from '../assets/images/branding/marketing_materials/mm_s_7.png';

import ssPic1 from '../assets/images/ssai/ssPic1.png';
import aiPic1 from '../assets/images/ssai/aiPic1.png';
import aiPic2 from '../assets/images/ssai/aiPic2.png';
import LinkToPortfolios from './LinkToPortfolios';


function Portfolio() {
    const { currentLanguage, setPage } = useAppContext();
    const [currentPageName, setCurrentPageName] = useState('Portfolio')

    useEffect(() => {
        setCurrentPageName(currentLanguage === 'English' ? 'Portfolio' : 'ፖርትፎሊዮ');
    }, [currentLanguage]);
    useEffect(() => {
        setPage('Portfolio');


    }, [])

    const webDesignRef = useRef();
    webDesignRef.name = currentLanguage === 'English' ? 'Website and App Design' : 'ዌብሳይትና መተግበሪያ ዲዛይን';



    const brandDesignRef = useRef();
    brandDesignRef.name = currentLanguage === 'English' ? 'Brand Design' : 'ብራንድ ዲዛይን';



    const officeSoftwareAIRef = useRef();
    officeSoftwareAIRef.name = currentLanguage === 'English' ? 'Office Software and AI powered Solutions' : 
    'የቢሮ ሶፍትዌርና AI መርህ መፍትሄዎች';




    const [webDesignInView, setWebDesignInView] = useState(false);



    const PortfolioListItemsEN = ['Website and App Design', 'Brand Design', 'Office Software and AI powered Solutions']
    const PortfolioListItemsAM = ['ዌብሳይትና መተግበሪያ ዲዛይን', 'ብራንድ ዲዛይን', 'የቢሮ ሶፍትዌርና AI መርህ መፍትሄዎች']

     
    const title = currentLanguage === 'English' ? 'Title' : 'ርዕስ';
    const type = currentLanguage === 'English' ? 'Type' : 'ዓይነት';
    const niche = currentLanguage === 'English' ? 'Niche' : 'መደብ';
    const download_msg = currentLanguage === 'English' ? 'Download APK for Android' : 'ለAndroid ስልክዎ ይጫኑ';
    const download_progress = currentLanguage === 'English' ? 'Your download starts shortly.።' : 'መተግበሪያዎ ማውረድ ይጀምራል..';

    const brandTextsEN = [
        'Brand cohesion',
        'Visual consistency',
        'Memorable and distinct logos',
        'Unique brand storytelling',
        'Efficient brand communication',
    ];
    const brandTextsAM = [
        "የብራንድና ምርት ትስስር",
        "የግራፊክስ የእይታ ወጥነት",
        "የማይረሱ እና የተለዩ አርማዎች",
        "ልዩ የምርት ትርክት",
        "ውጤታማ የምርት ብራንድ አቀራርብ",
    ];


    const webAppTextsEN = {
        'web_design': [
            'Responsive web design',
            'Seamless user experience',
            'Engaging interactive elements',
            'Optimized page performance',
            'Accessible and inclusive designs',
        ],
        'app_design': [
            'Intuitive user interfaces',
            'Efficient workflow integration',
            'Seamless cross-platform functionality',
            'Innovative features for user engagement',
            'Sleek and modern design elements',
        ],
    }
    const webAppTextsAM = {
        'web_design': [
            "ለአጠቃቀም ምቹ የሆኑ ዌብሳይት ዲዛይኖች",
            "እንከን የለሽ የተጠቃሚ ተሞክሮ",
            'አሳታፊ የመስተጋብር አካላት',
            'ፈጣን የገጽ አቅርቦት',
            "ተደራሽ እና አካታች ንድፎች",
        ],
        'app_design': [
            'ግንዛቤን መሠረት ያደረገ የተጠቃሚ በይነገጽ',
            "ውጤታማ የስራ ፍሰት ውህደት ",
            "እንከን የለሽ የተጠቃሚ ተሞክሮ",
            'ተጠቅሚን የሚስቡ የዲዛይን ባህሪያት',
            "የሚያምሩ እና ዘመናዊ ንድፍ አካላት",
        ],
    }

    const brandTexts = currentLanguage === 'English' ? brandTextsEN : brandTextsAM;
    const webAppTexts = currentLanguage === 'English' ? webAppTextsEN : webAppTextsAM;

    const observeRef = (ref, setInView) => {
        const observer = new IntersectionObserver(
            entries => {
                setInView(entries[0].isIntersecting);
            },
            { threshold: 0.5 } // Adjust threshold as needed
        );

        observer.observe(ref.current);

        return () => observer.disconnect();
    };

    useEffect(() => {

        observeRef(webDesignRef, setWebDesignInView);

    }, []);



    const [getInTouch, setGetInTouch] = useState(false);
    const [emailUs, setEmailUs] = useState(false);
    const [callUs, setCallUs] = useState(false);


    const handleCTAClick = () => {
        setGetInTouch(true);
    }

    const handleEmailUs = () => {
        setEmailUs(true);
        setCallUs(false);
    }
    const handleCallUs = () => {
        setCallUs(true);
        setEmailUs(false);
    }

    const handleCancel = () => {
        setGetInTouch(false);
        setEmailUs(false);
    }



    const CtaButton = () => {
        const labelButton = currentLanguage === 'English' ? 'Get In Touch' : 'ያግኙን';
        return (
            <button className="cta-button" onClick={handleCTAClick}
                style={{
                    transform: `${getInTouch ? "translateY(-10px) scale(0.8)" : ""} ${emailUs ? "translateY(-20px)" : ""}`,
                    filter: getInTouch ? "grayscale(100%)" : "none",
                    fontWeight: getInTouch ? "normal" : "bold",
                }}>{labelButton}</button>
        )
    }

    const LinkButton = ({ link }) => {

        const visitWebsite = currentLanguage === 'English'?'Visit Site':'ጣቢያውን ይጎብኙ';
        const handleCTAClick = () => {
            if (link) {
                window.open(link, '_blank');
            }
        };

        return (
            <button className="link-to-site" onClick={handleCTAClick}>
                {visitWebsite} <img src={iconVisitSite} alt="arrow icon" className='visit-icon' />
            </button>
        );
    };


    const [ref, inView] = useInView({
        triggerOnce: true, // Only trigger once when the component comes into view
    });



    useEffect(() => {

        // Update the text every 2 seconds (adjust the time interval as needed)
        const intervalId = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * brandTexts.length);


        }, 2000);

        // Clear the interval when the component is unmounted or out of view
        return () => clearInterval(intervalId);

    }, []);


    const [selectedBrand, setSelectedBrand] = useState('logo');
    const [applyTransition, setApplyTransition] = useState(false);

    const [selectedSoftware, setSelectedSoftware] = useState('office_software');
    const [selectedDesign, setSelectedDesign] = useState('web_design');

    // const brand_images = [brandPic1, brandPic2, brandPic3,
    //   brandPic4, brandPic5, brandPic6, brandPic7];
    const brand_images = {
        logo: [brandLogo1, brandLogo2, brandLogo3, brandLogo4,
            brandLogo5, brandLogo6, brandLogo7],
        business_cards: [brandBc1, brandBc2, brandBc3, brandBc4,
            brandBc5, brandBc6, brandBc7],
        marketing_materials: [brandMm1, brandMm2, brandMm3, brandMm4,
            brandMm5, brandMm6, brandMm7],

    };

    const webapp_images = {
        web_design: [webPic1, webPic2],
        app_design: [appPic1, appPic1_2],
    }

    const ssai_images = {
        office_software: [ssPic1],
        ai_advancements: [aiPic1, aiPic2],


    };
    const handleOptionBrand = (option) => {
        setSelectedBrand(option);
    };

    const handleOptionSoftware = (option) => {
        setSelectedSoftware(option);
    };

    const handleOptionDesign = (option) => {
        setSelectedDesign(option);
    };

    const [progressMessage, setProgressMessage] = useState(false);

    useEffect(() => {

        // Set a timeout to remove progress message
        const timeoutId = setTimeout(() => {
            setProgressMessage(false);
        }, 3000);

        // Clear the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);
    }, [progressMessage]);

    const handleDownload = () => {
        setProgressMessage(true);

        const filePath = process.env.PUBLIC_URL + '/roulette-app.apks';
        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'roulette-app.apks';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);



    };

    useEffect(() => {
        // Update the applyTransition state when selectedOption changes
        setApplyTransition(true);

        // Set a timeout to remove the transition class after the transition duration
        const timeoutId = setTimeout(() => {
            setApplyTransition(false);
        }, 350); // Adjust the duration to match your transition duration

        // Clear the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);
    }, [selectedBrand, selectedSoftware, selectedDesign]);

    const webAppDemosEN = {
        'web_design': [
            {
                content: {
                    title: 'nayeDesigns',
                    type: 'Ecommerce',
                    niche: 'Artisanal',
                    body: 'Full stack Ecommerce site. Mobile and Desktop. Built with React, React Native and Node.js',
                    indicator: 'website',
                },
                link: 'https://naye-web.onrender.com/',
                image: webPic1,
                video: webVid1,
                type: 'website',
            },
            {
                content: {
                    title: 'Data Analytics dashboard',
                    type: 'Dashboard design',
                    niche: 'World Bank Indicators',
                    body: 'Full stack Dashboard system. Built with React and Node.js',
                    indicator: 'website',
                },
                link: 'https://db-osc.onrender.com/',
                image: webPic2,
                video: webVid2,
                type: 'website',
            },
        ],
        'app_design': [
            {
                content: {
                    title: 'Roulette|ሮሌት',
                    type: 'Android App',
                    niche: 'Entertainment',
                    body: 'A roulette game app. Built for Android with React Native and Expo.',
                },
                link: 'https://expo.dev/@babexpo/rouletteMain?serviceType=classic&distribution=expo-go',
                image: appPic1,
                video: appVid1,
                type: 'app',
            },
            {
                content: {
                    title: 'Roulette|ሮሌት',
                    type: 'Android App',
                    niche: 'Entertainment',
                    body: 'A roulette game app. Built for Android with React Native and Expo.',
                },
                link: 'https://expo.dev/@babexpo/rouletteMain?serviceType=classic&distribution=expo-go',
                image: appPic1_2,
                video: appVid1,
                type: 'app',
            },
        ],
    };

    const webAppDemosAM = {
        'web_design': [
            {
                content: {
                    title: 'nayeDesigns',
                    type: 'ኢኮሜርስ ሱቅ',
                    niche: 'እጅ ሥራና የአገር ውስጥ እቃዎች',
                    body: 'ፉል ስታክ የዌብሳይት ኢኮሜርስ ሱቅ ፤ ሞባይልና ዴስክቶፕ፡፡ በ React JS ፡ React Native አና በ Node.js የተገነባ',
                    indicator: 'website',
                },
                link: 'https://naye-web.onrender.com/',
                image: webPic1,
                video: webVid1,
                type: 'website',
            },
            {
                content: {
                    title: 'Data Analytics dashboard',
                    type: 'ዳሽቦርድ',
                    niche: 'ዎርልድ ባንክ መለኪያዎች',
                    body: 'ፉል ስታክ ዳሽቦርድ ሲስተም. በ React JS አና በ Node.js የተገነባ',
                    indicator: 'website',
                },
                link: 'https://db-osc.onrender.com/',
                image: webPic2,
                video: webVid2,
                type: 'website',
            },
        ],
        'app_design': [
            {
                content: {
                    title: 'Roulette|ሮሌት',
                    type: 'የAndroid መተግበሪያ',
                    niche: 'መዝናኛ',
                    body: 'ሮሌት መጫወቻ ፤ በ React Native አና Expo የተገነባ',
                },
                link: 'https://expo.dev/@babexpo/rouletteMain?serviceType=classic&distribution=expo-go',
                image: appPic1,
                video: appVid1,
                type: 'app',
            },
            {
                content: {
                    title: 'Roulette|ሮሌት',
                    type: 'የAndroid መተግበሪያ',
                    niche: 'መዝናኛ',
                    body: 'ሮሌት መጫወቻ ፤ በ React Native አና Expo የተገነባ',
                },
                link: 'https://expo.dev/@babexpo/rouletteMain?serviceType=classic&distribution=expo-go',
                image: appPic1_2,
                video: appVid1,
                type: 'app',
            },
        ],
    };


    const brandDesignIntroEN = {
        'logo': {
            motto: 'Distinctive Symbols, Lasting Impressions',
            body: 'A logo is your brand\'s visual signature. In a glance, it communicates your essence, values, and uniqueness. Elevate your identity with a thoughtfully crafted logo that leaves a lasting mark on your audience.',
        },


        'business_cards': {
            motto: 'Networking Redefined, One Card at a Time',
            body: 'More than contact details, business cards are your brand\'s tangible representation. Make a lasting impression with cards that reflect professionalism and uniqueness in every handshake.',

        },


        'marketing_materials': {
            motto: 'Strategic Designs, Powerful Messages',
            body: 'Marketing materials are your brand\'s voice in print. From brochures to banners, each piece speaks volumes about your products or services. Elevate your marketing game with visually compelling materials that captivate and convert.',
        },


    };
    const brandDesignIntroAM = {
        'logo': {
            motto: 'ልዩ ምልክቶች፣ ዘላቂ ምልክታዎች',
            body: 'የድርጅትዎ አርማ የምርትዎ ምስላዊ ፊርማ ነው። በቀላልና ፈጣን ሁኔታም የእርስዎንና የድርጅትዎን ማንነት፣ እሴቶች እና ልዩነት ለተጠቃሚዎችው ያስተላልፋል። በታዳሚዎችዎ ላይ ዘላቂ ትውስታን በሚተው ታስቦለት በተሰራ አርማ ማንነትዎን ከፍ ያድርጉ።',
        },


        'business_cards': {
            motto: 'ኔትዎርኪንግ በልዩ መልኵ፤ አንድ ካርድ በአንድ ጊዜ',
            body: 'ከኮንታክት አድራሻ ዝርዝሮችዎ በላይ፣ የንግድ ካርዶች የምርትዎ ተጨባጭ ውክልና ናቸው። በእያንዳንዱ የእጅ መጨባበጥ ሙያዊነትን እና ልዩነትን በሚያንፀባርቁ ካርዶች ዘላቂ ስሜትን ይፍጠሩ።',

        },


        'marketing_materials': {
            motto: 'ስልታዊ ንድፎች፣ ተጽዕኖ ፈጣሪ መልዕክቶች',
            body: 'የመረጃ/የማስታወቂያ ሥራዎች በህትመት ውስጥ የእርስዎ የምርት ስም ድምጽ ናቸው። ከብሮሹሮች እስከ ባነሮች፣ እያንዳንዱ መልእክት ስለ ምርቶችዎ ወይም አገልግሎቶችዎ ብዙ ይናገራል። የግብይት ጨዋታዎን በሚማርኩና ተጠቃሚ በሚያመጡ አሳማኝ የግብይት ሥራዎች ከፍ ያድርጉ።',
        },


    };
    const ssaiIntroEN = {

        'ai_advancements': {
            motto: 'Elevate Efficiency, Unleash Potential: AI Empowering Businesses.',
            body: 'In today\'s dynamic business landscape, AI isn\'t just a tool; it\'s a game-changer. Experience the future with our AI-powered advancements. From data analytics that unveil hidden insights to workflow enhancement that streamlines operations, and seamless data organization, we redefine what\'s possible. Stay ahead, stay efficient – because in the age of AI, your business isn\'t just adapting; it\'s thriving.',
        },
        'office_software': {
            motto: 'Transforming Workspaces, Empowering Futures: Unleash Automation with Our Office Software Solutions.',
            body: 'Step into the future of seamless operations with our cutting-edge office software solutions. Automation is at our core, streamlining tasks and boosting productivity. Our scalable solutions grow with your business, adapting to your needs effortlessly. Dive into a world where software architecture meets innovation, redefining the way you work. Elevate your office experience – efficient, scalable, and built for success.'

        }
    }

    const ssaiIntroAM = {

        'ai_advancements': {
            motto: 'ቅልጥፍናን ያሳድጉ፣ እምቅ አቅምን ይጠቀሙ፤ AI ለዘላቂ የንግድ ሥራ',
            body: 'በዛሬው ተለዋዋጭ የሥራ መልክዓ ምድር, AI አጋዥ መሣሪያ ብቻ አይደለም፤ ጨዋታ ቀያሪም ጭምር እንጂ። የወደፊቱን ዛሬ ይሞክሩ፤ ለሥራዎም አፋጣኝ ምላሽ ያግኙ። የተደበቁ ግንዛቤዎችን ከሚገልጥ የውሂብ(ዳታ) ትንታኔ እስከ የስራ ፍሰት ማቀላጠፊያ ስራዎችን እና እንከን የለሽ የውሂብ አደረጃጀት ከእኛ ጋር አብረው ይከውኑ። ወደፊት ይቅደሙ፣ በብቃትም ይቆዩ - ምክንያቱም በ AI ዘመን፣ ንግድዎ መላመድ ብቻ መበልጸግም አለበት።',
        },
        'office_software': {
            motto: 'ሥራዎን ያዘምኑ፣ የወደፊት የሥራ ውጤቶን ያጎልብቱ፡ ክአውቶሜሽን የቢሮ ሶፍትዌር መፍትሄዎች ልቀው ይገኙ',
            body: 'እንከን የለሽ ክንውኖች ወደሚበረታታበት የሥራ ዓለም ከእኛ ጋር በሚገነቡት ዘመናዊ ሶፍትዌሮች ይቀላቀሉ። አውቶሜሽን ስራን ማቀላጠፍ እና ምርታማነትን ማጎልበት ዋና ስራችን ነው። የእኛ የሚሰፉና የሚባዙ የሶፍትዌር ሥራዎች ከንግድዎ ጋር ያድጋሉ፣ ከፍላጎቶችዎ ጋር በቀላሉ ይላመዳሉ። የሶፍትዌር አርክቴክቸር ፈጠራን ወደ ሚያሟላበት አለም ይዝለሉ፣ የሚሰሩበትን መንገድ እንደገና ይቀይሱ። የቢሮ ቆይታዎን ምቹ ያድርጉ - ቀልጣፋ፣ ሊሰፋ የሚችል እና ለስኬት የተገነባ። '

        }
    }


    const ssaiQuotesEN = {
        ai_advancements: [
            {
                quote: "AI is quickly allowing developing countries to solve pressing challenges in critical sectors like health, energy, agriculture, and financial services.",
                author: "David Malpass, President of the World Bank Group"
            },
            {
                quote: "Technology has been a lifeline to developing countries during the COVID-19 crisis, helping to maintain essential services and keep companies in business.",
                author: "Kristalina Georgieva, Managing Director of the International Monetary Fund (IMF)"
            },
            {
                quote: "Even before the pandemic, commercial uses for AI were expanding rapidly in emerging markets, in fields ranging from manufacturing and energy to education and financial services.",
                author: "David Malpass, President of the World Bank Group"
            },

        ],
        office_software: [
            {
                quote: "Software and computer services centrally underpin the development and growth of economies worldwide.",
                author: "UNCTAD (United Nations Conference on Trade and Development)"
            },

            {
                quote: "Digital transformation, including the adoption of office software solutions, plays a crucial role in enhancing labor productivity and economic development in developing countries.",
                author: "Emerald Insight"
            },

            {
                quote: "Developing countries need to diversify their production towards more technologically advanced sectors, leveraging smart technologies like Industry 4.0 to drive inclusive growth.",
                author: "World Economic Forum"
            }
        ]
    };

    const ssaiQuotesAM = {
        ai_advancements: [
            {
                quote: "AI በማደግ ላይ ያሉ ሀገራት በፍጥነት እንደ ጤና፣ ኢነርጂ፣ ግብርና እና የፋይናንስ አገልግሎቶች ባሉ ወሳኝ ዘርፎች ላይ አንገብጋቢ ተግዳሮቶችን እንዲፈቱ እየረዳቸው ነው።",
                author: "ዴቪድ ማልፓስ, የዎርልድ ባንክ ግሩፕ ፕሬዝደንት"
            },
            {
                quote: "ቴክኖሎጂ በኮቪድ-19 ቀውስ ወቅት ለታዳጊ ሀገራት የህይወት መስመር ሆኖ አስፈላጊ አገልግሎቶችን ለመጠበቅ እና ኩባንያዎችን በንግድ ስራ ላይ ለማቆየት ረድቷል፡፡",
                author: "ክሪስታሊና ጂኦርጊቫ, የአይ.ኤም.ኤፍ ዋና ስራ አስፈፃሚ"
            },
            {
                quote: "የኮቪድ-19 ወረርሽኝ ከመከሰቱ በፊት እንኳን AI በማደግ ላይ ባሉ ገበያዎች ፣ ከማኑፋክቸሪንግ እና ከኃይል እስከ ትምህርት እና የፋይናንስ አገልግሎቶች ባሉ መስኮች በፍጥነት እየተስፋፉ ነበር።",
                author: "ዴቪድ ማልፓስ, የዎርልድ ባንክ ግሩፕ ፕሬዝደንት"
            },

        ],
        office_software: [
            {
                quote: "የሶፍትዌር እና የኮምፒዩተር አገልግሎቶች በአለም አቀፍ ደረጃ የኢኮኖሚ እድገትን በማዕከላዊነት ይደግፋሉ።",
                author: "የተባበሩት መንግስታት የንግድ እና ልማት ኮንፈረንስ (UNCTAD)"
            },

            {
                quote: "ዲጂታል ለውጥ ፣ የቢሮ ሶፍትዌር መፍትሄዎችን መቀበልን መጠቀምን ጨምሮ በማደግ ላይ ባሉ ሀገራት የሰው ኃይል ምርታማነትን እና ኢኮኖሚያዊ እድገትን በማጎልበት ረገድ ወሳኝ ሚና ይጫወታል፡፡",
                author: "ኤመራልድ ኢንስይት"
            },

            {
                quote: "በማደግ ላይ ያሉ አገሮች ሁሉን አቀፍ እድገት ለማምጣት እንደ ኢንዱስትሪ 4.0 ያሉ ዘመናዊ ቴክኖሎጂዎችን በመጠቀም ምርታቸውን በቴክኖሎጂ ወደ ላቀ ዘርፎች ማስፋፋት አለባቸው።",
                author: "የዓለም ኢኮኖሚ መድረክ (WEF)"
            }
        ]
    };



    const pageSectionColors = ['#eceee0', '#ddd']

    const [brandHovered, setBrandHovered] = useState('');
    const [ssaiHovered, setSsaiHovered] = useState('');

    const handleBrandHover = (hovered) => {
        setBrandHovered(hovered);
    };

    const handleSsaiHover = (hovered) => {
        setSsaiHovered(hovered);
    };

    const mainheader_title = currentLanguage === 'English' ? 'Elevating Ideas, Empowering Business: Your Tech Ally in Digital Transformation.'
        :
        'ሀሳቦትን እውን ማድረግ፣ የቢዝነስዎን ተደራሽነት ማጎልብት፡፡ የእርስዎ የቴክኖሎጂ አጋር በዲጂታል ትራንስፎርሜሽን ጉዞዎ!';

    const subheader_title_web = currentLanguage === 'English' ? 'Website and App Design' : 'የዌብሳይትና መተግበሪያ ዲዛይን';
    const subheader_title_brand = currentLanguage === 'English' ? 'Brand Design' : 'ብራንድ ዲዛይን';
    const subheader_title_ssai = currentLanguage === 'English' ? 'Office Software and AI powered Solutions' : 'የቢሮ ሶፍትዌርና AI መርህ መፍትሄዎች';
    const webAppDemos = currentLanguage === 'English' ? webAppDemosEN : webAppDemosAM;
    const menuWebApp = currentLanguage === 'English' ? ['Web Design', 'App Design'] : ['ዌብሳይት ዲዛይን', 'መተግበሪያ ዲዛይን'];
    const menuBrand = currentLanguage === 'English' ? ['Logo', 'Business Cards', 'Marketing Materials'] : ['የካምፓኒ አርማ', 'ቢዝነስ ካርዶች', 'የመረጃ/ማስታወቅያ ሥራዎች'];
    const menuSsai = currentLanguage === 'English' ? ['Office Software Solutions', 'AI Powered Solutions'] : ['የቢሮ ሶፍትዌር', ' AI መርህ መፍትሄዎች'];
    const brandDesignIntro = currentLanguage === 'English' ? brandDesignIntroEN : brandDesignIntroAM;
    const ssaiIntro = currentLanguage === 'English' ? ssaiIntroEN : ssaiIntroAM;
    const ssaiQuotes = currentLanguage === 'English' ? ssaiQuotesEN : ssaiQuotesAM;
    const PortfolioListItems = currentLanguage === 'English' ? PortfolioListItemsEN : PortfolioListItemsAM;
    const headerText = currentLanguage === 'English' ? PortfolioListItemsEN[2] : PortfolioListItemsAM[2];
    const emailUs_msg = currentLanguage === 'English' ? 'Email Us' : 'ኢሜይል ይጻፉልን';
    const callUs_msg = currentLanguage === 'English' ? 'Call Us' : 'ይደውሉልን';

    return (
        <div className='main-container'>

            <div className='sub-container'>
                {/* <ScreenSize /> */}


                {/* <NavigationBar /> */}

                <div className="motto">
                    <h1>{mainheader_title}</h1>
                </div>

                <div className='portfolio-showcase-container' >

                    <div className='sub-portfolio' ref={webDesignRef}
                        style={{ backgroundColor: pageSectionColors[1 % pageSectionColors.length], position: 'relative' }}
                    >
                        <h2 className='sub-header' >{subheader_title_web}</h2>


                        <div className='bento'>
                            <div style={{ width: '100%' }} >
                                <div style={{ display: 'flex', justifyContent: 'space-between', }}
                                    className='bento-menu-webApp'>
                                    <div
                                        className={`bento-menu ${selectedDesign === 'web_design' ? 'selected-menu' : ''}`}
                                        onClick={() => handleOptionDesign('web_design')}>{menuWebApp[0]}</div>
                                    <div
                                        className={`bento-menu ${selectedDesign === 'app_design' ? 'selected-menu' : ''}`}
                                        onClick={() => handleOptionDesign('app_design')}>{menuWebApp[1]}</div>


                                </div>
                                <div className='webApp-bento-3grid'
                                    style={{ position: 'relative' }}>


                                    <div className='demo-item'
                                        style={{
                                            borderRadius: '4px 8px', border: '1px solid #474554'
                                        }} >
                                            
                                        <img src={webAppDemos[selectedDesign][0].image} alt='website/app front design snapshot, made by brosfe'
                                            className={`${webAppDemos[selectedDesign] && webAppDemos[selectedDesign][0].type === 'app' ? 'fade-image-app' : 'fade-image'}
                                            ${applyTransition ? 'fade-transition' : ''}`}/>
                                            
                                        <div>
                                            <p>{title}-<span style={{ fontWeight: 'bold' }}>{webAppDemos[selectedDesign][0].content.title}</span></p>
                                            <p>{type}- <span style={{ fontWeight: 'bold' }}>{webAppDemos[selectedDesign][0].content.type}</span></p>
                                            <p>{niche}- <span style={{ fontWeight: 'bold' }}>{webAppDemos[selectedDesign][0].content.niche}</span></p>
                                            <p>{webAppDemos[selectedDesign][0].content.body}</p>
                                        </div>

                                        <LinkButton link={webAppDemos[selectedDesign][0].link} />
                                        {selectedDesign === 'app_design' &&
                                            <button className='link-to-site' onClick={handleDownload}>
                                                <img src={downloadIcon} alt="download icon" className='visit-icon' /> {download_msg}
                                            </button>
                                        }





                                    </div>
                                    <div className='demo-item'
                                        style={{ borderRadius: '4px 8px', border: '1px solid #474554' }} >
                                        <img src={webAppDemos[selectedDesign][1].image} alt='website/app front design snapshot, made by brosfe'
                                            className={`${webAppDemos[selectedDesign] && webAppDemos[selectedDesign][0].type === 'app' ? 'fade-image-app' : 'fade-image'}
                                            ${applyTransition ? 'fade-transition' : ''}`}/>

                                            
                                        <div>
                                            <p>Title-<span style={{ fontWeight: 'bold' }}>{webAppDemos[selectedDesign][1].content.title}</span></p>
                                            <p>Type- <span style={{ fontWeight: 'bold' }}>{webAppDemos[selectedDesign][1].content.type}</span></p>
                                            <p>Niche- <span style={{ fontWeight: 'bold' }}>{webAppDemos[selectedDesign][1].content.niche}</span></p>
                                            <p>{webAppDemos[selectedDesign][1].content.body}</p>
                                        </div>
                                        <LinkButton link={webAppDemos[selectedDesign][1].link} />
                                        {selectedDesign === 'app_design' &&
                                            <button className='link-to-site' onClick={handleDownload}>
                                                <img src={downloadIcon} alt="download icon" className='visit-icon' /> {download_msg}
                                            </button>
                                        }

                                    </div>
                                    <DynamicList title={selectedDesign === 'web_design' ? 'Web Design' : 'App Design'} items={webAppTexts[selectedDesign]} inView={webDesignInView} />
                                    {progressMessage && (

                                        <span className='download-message'>{download_progress}</span>

                                    )}
                                </div>


                            </div>



                        </div>



                    </div>
                    <div className='sub-portfolio' ref={brandDesignRef}
                        style={{ backgroundColor: pageSectionColors[0 % pageSectionColors.length] }}>
                        <h2 className='sub-header'>{subheader_title_brand}</h2>

                        <div className='bento'>
                            <div className='bento-left-brand'
                                style={{ width: '100%' }}>
                                <div style={{
                                    display: 'flex', justifyContent: 'space-between', width: '100%', minHeight: '5%',
                                }}>
                                    <div
                                        className={`bento-menu ${selectedBrand === 'logo' ? 'selected-menu' : ''}`}
                                        onClick={() => handleOptionBrand('logo')}>{menuBrand[0]}</div>
                                    <div
                                        className={`bento-menu ${selectedBrand === 'business_cards' ? 'selected-menu' : ''}`}
                                        onClick={() => handleOptionBrand('business_cards')}>{menuBrand[1]}</div>

                                    <div
                                        className={`bento-menu ${selectedBrand === 'marketing_materials' ? 'selected-menu' : ''}`}
                                        onClick={() => handleOptionBrand('marketing_materials')}>{menuBrand[2]}</div>

                                </div>
                                <div className={`bento-top ${selectedBrand === 'logo' || selectedBrand === 'marketing_materials' ? 'grid-flexed-top' : ''}`}>

                                    {selectedBrand && (
                                        <div onMouseEnter={() => handleBrandHover(brand_images[selectedBrand.toLowerCase()][0])}
                                            onMouseLeave={() => handleBrandHover(null)}>
                                            <img
                                                src={brand_images[selectedBrand.toLowerCase()][0]} // Assuming you want to display the first image in the array
                                                alt='Sample brand design, made by brosfe'
                                                className={`fade-image ${applyTransition ? 'fade-transition' : ''}`}
                                            />
                                        </div>

                                    )}
                                    {selectedBrand && (
                                        <div style={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'center', alignItems: 'center',
                                        }}>
                                            <h3>{brandDesignIntro[selectedBrand]['motto']}</h3>
                                            <p>{brandDesignIntro[selectedBrand]['body']}</p>
                                        </div>
                                    )}
                                </div>
                                <div className={`brand-bento-4grid ${selectedBrand === 'business_cards' ? 'grid-flexed' : ''}`}>




                                    <div onMouseEnter={() => handleBrandHover(brand_images[selectedBrand.toLowerCase()][1])}
                                        onMouseLeave={() => handleBrandHover(null)} >
                                        {selectedBrand && (
                                            <img
                                                src={brand_images[selectedBrand.toLowerCase()][1]} // Assuming you want to display the first image in the array
                                                alt='sample brand design, made by brosfe'
                                                className={`fade-image ${applyTransition ? 'fade-transition' : ''}`}
                                            />
                                        )}
                                    </div>
                                    <div onMouseEnter={() => handleBrandHover(brand_images[selectedBrand.toLowerCase()][2])}
                                        onMouseLeave={() => handleBrandHover(null)}>
                                        {selectedBrand && (
                                            <img
                                                src={brand_images[selectedBrand.toLowerCase()][2]} // Assuming you want to display the first image in the array
                                                alt='sample brand design, made by brosfe'
                                                className={`fade-image ${applyTransition ? 'fade-transition' : ''}`}
                                            />
                                        )}
                                    </div>
                                    <div onMouseEnter={() => handleBrandHover(brand_images[selectedBrand.toLowerCase()][3])}
                                        onMouseLeave={() => handleBrandHover(null)}>
                                        {selectedBrand && (
                                            <img
                                                src={brand_images[selectedBrand.toLowerCase()][3]} // Assuming you want to display the first image in the array
                                                alt='sample brand design, made by brosfe'
                                                className={`fade-image ${applyTransition ? 'fade-transition' : ''}`}
                                            />
                                        )}
                                    </div>
                                    <div onMouseEnter={() => handleBrandHover(brand_images[selectedBrand.toLowerCase()][4])}
                                        onMouseLeave={() => handleBrandHover(null)}>
                                        {selectedBrand && (
                                            <img
                                                src={brand_images[selectedBrand.toLowerCase()][4]} // Assuming you want to display the first image in the array
                                                alt='sample brand design, made by brosfe'
                                                className={`fade-image ${applyTransition ? 'fade-transition' : ''}`}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {brandHovered && (
                                <div className='bento-hovered-container'>
                                    <img src={brandHovered} alt='sample brand design, made by brosfe'></img>
                                </div>
                            )}

                            <div className='bento-right-brand'>
                                <div style={{
                                    display: 'flex', flexDirection: selectedBrand === 'business_cards' ? 'column' : 'row',
                                    gap: '5px'
                                }}>
                                    {selectedBrand && (
                                        <>
                                            <img
                                                src={brand_images[selectedBrand.toLowerCase()][5]} // Assuming you want to display the first image in the array
                                                alt='sample brand design, made by brosfe'
                                                style={{
                                                    width: selectedBrand === 'logo' ? '100%' : selectedBrand === 'business_cards' ? '100%' : '50%',
                                                    height: selectedBrand === 'logo' ? '100%' : selectedBrand === 'business_cards' ? '50%' : '100%',
                                                    border: '1px solid #474554',
                                                    borderRadius: '4px 8px'
                                                }}
                                                onMouseEnter={() => handleBrandHover(brand_images[selectedBrand.toLowerCase()][5])}
                                                onMouseLeave={() => handleBrandHover(null)}></img>

                                            {(selectedBrand === 'business_cards' || selectedBrand === 'marketing_materials') && (
                                                <img
                                                    src={brand_images[selectedBrand.toLowerCase()][6]} // Assuming you want to display the first image in the array
                                                    alt='sample brand design, made by brosfe'
                                                    style={{
                                                        width: selectedBrand === 'business_cards' ? '100%' : '50%',
                                                        height: selectedBrand === 'business_cards' ? '50%' : '100%',
                                                        border: '1px solid #474554',
                                                        borderRadius: '4px 8px'
                                                    }}
                                                    onMouseEnter={() => handleBrandHover(brand_images[selectedBrand.toLowerCase()][6])}
                                                    onMouseLeave={() => handleBrandHover(null)}></img>
                                            )}
                                        </>
                                    )}
                                </div>
                                {currentLanguage === 'English' && (
                                    <div >
                                        <TypeAnimation
                                            sequence={[

                                                'Brand Cohesion',
                                                1000,
                                                'Visual Consistency',
                                                1000,
                                                'Memorable and distinct logos',
                                                1000,
                                                'Unique brand storytelling',
                                                1000,
                                                'Efficient brand communication',
                                                1000,

                                            ]}
                                            wrapper="span"
                                            speed={50}
                                            style={{ display: 'inline-block', textTransform: 'capitalize' }}


                                            repeat={Infinity}
                                        />
                                    </div>

                                )}
                                {currentLanguage === 'አማርኛ' && (
                                    <div >
                                        <TypeAnimation
                                            sequence={[




                                                "የብራንድና ምርት ትስስር",
                                                1000,
                                                "የግራፊክስ የእይታ ወጥነት",
                                                1000,
                                                "የማይረሱ እና የተለዩ አርማዎች",
                                                1000,
                                                "ልዩ የምርት ትርክት",
                                                1000,
                                                "ውጤታማ የምርት ብራንድ አቀራርብ",
                                                1000,

                                            ]}
                                            wrapper="span"
                                            speed={50}
                                            style={{ display: 'inline-block', textTransform: 'capitalize' }}


                                            repeat={Infinity}
                                        />
                                    </div>)}


                            </div>


                        </div>

                    </div>


                    <div className='sub-portfolio' ref={officeSoftwareAIRef}
                        style={{ backgroundColor: pageSectionColors[1 % pageSectionColors.length] }}>
                        <h2 className='sub-header'>{subheader_title_ssai}</h2>

                        <div className='bento-ssai'>
                            <div className='bento-left' >
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '10%' }}
                                    className='bento-menu-ssai'>
                                    <div
                                        className={`bento-menu ${selectedSoftware === 'office_software' ? 'selected-menu' : ''}`}
                                        onClick={() => handleOptionSoftware('office_software')}>{menuSsai[0]}</div>
                                    <div
                                        className={`bento-menu ${selectedSoftware === 'ai_advancements' ? 'selected-menu' : ''}`}
                                        onClick={() => handleOptionSoftware('ai_advancements')}>{menuSsai[1]}</div>


                                </div>
                                <div style={{ width: '100%', borderRadius: '4px 8px', border: '1px solid #474554' }}
                                    className='bento-img-container'>

                                    {selectedSoftware && (
                                        <div style={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'center', alignItems: 'center', marginTop: '10px',
                                        }}>
                                            <h3>{ssaiIntro[selectedSoftware]['motto']}</h3>
                                            <p>{ssaiIntro[selectedSoftware]['body']}</p>
                                        </div>
                                    )}
                                </div>
                                <div className='ssai-bento-2grid'>

                                    <div onMouseEnter={() => handleSsaiHover(ssai_images[selectedSoftware.toLowerCase()][0])}
                                        onMouseLeave={() => handleSsaiHover(null)}>
                                        {selectedSoftware && (
                                            <img
                                                src={ssai_images[selectedSoftware.toLowerCase()][0]} // Assuming you want to display the first image in the array
                                                alt="An abstract representation of cutting-edge technology and innovation, reflecting brosfe's expertise in website and app design, as well as office software innovation and AI consultancy. The image embraces a modern aesthetic with predominant 
                                                    modern technology use cases and necessities for effective work at a modern office and overall in a community, evoking professionalism and creativity."

                                                className={`fade-image ${applyTransition ? 'fade-transition' : ''}`}
                                            />
                                        )}
                                    </div>
                                    {currentLanguage === 'English' && (
                                        <div >
                                            {selectedSoftware === "office_software" && (
                                                <TypeAnimation
                                                    sequence={[

                                                        'Robust software architecture',
                                                        1000,
                                                        'Scalable and flexible solutions',
                                                        1000,
                                                        'Automation of complex processes',
                                                        1000,
                                                        'Cloud-based collaboration platforms',
                                                        1000,
                                                        'Real-time data analytics',
                                                        1000,

                                                    ]}
                                                    wrapper="span"
                                                    speed={50}
                                                    style={{ display: 'inline-block', textTransform: 'capitalize' }}

                                                    repeat={Infinity}
                                                />

                                            )}
                                            {selectedSoftware === "ai_advancements" && (
                                                <TypeAnimation
                                                    sequence={[

                                                        'AI for efficient task automation',
                                                        1000,
                                                        'Enhance workflow with smart assistants',
                                                        1000,
                                                        'Improve data organization',
                                                        1000,
                                                        'Explore AI-powered analytics for insights',
                                                        1000,
                                                        'Implement AI to optimize business processes',
                                                        1000,

                                                    ]}
                                                    wrapper="span"
                                                    speed={50}
                                                    style={{ display: 'inline-block', textTransform: 'capitalize' }}

                                                    repeat={Infinity}
                                                />

                                            )}


                                        </div>
                                    )}
                                    {currentLanguage === 'አማርኛ' && (
                                        <div >
                                            {selectedSoftware === "office_software" && (
                                                <TypeAnimation
                                                    sequence={[

                                                        'ጠንካራ የሶፍትዌር አርክቴክቸር',
                                                        1000,
                                                        'ሊሰፉና ሊሻሻሉ የሚችሉ ተለዋዋጭ መፍትሄዎች',
                                                        1000,
                                                        'ውስብስብ ሂደቶችን አውቶሜት ያድርጉ',
                                                        1000,
                                                        'ክላውድ ሰርቪስ ላይ መሠረት ያደረጉ የትብብር መድረኮች',
                                                        1000,
                                                        'ጊዜያዊ ውሂብ ትንታኔ',
                                                        1000,

                                                    ]}
                                                    wrapper="span"
                                                    speed={50}
                                                    style={{ display: 'inline-block', textTransform: 'capitalize' }}

                                                    repeat={Infinity}
                                                />

                                            )}
                                            {selectedSoftware === "ai_advancements" && (
                                                <TypeAnimation
                                                    sequence={[

                                                        'AI ለውጤታም ሥራ አውቶሜሽን ብቃት',
                                                        1000,
                                                        'በSmart AI ረዳቶች የሥራ ፍሰቶን ያሻሽሉ',
                                                        1000,
                                                        'የውሂብ (ዳታ) አደረጃጀትዎን ያሳድጉ',
                                                        1000,
                                                        'ለግንዛቤዎ በ AI የተጎላበተ ትንታኔን ይጠቀሙ',
                                                        1000,
                                                        'የንግድ ሂደቶችን ለማሻሻል AIን ይገልገሉ',
                                                        1000,

                                                    ]}
                                                    wrapper="span"
                                                    speed={50}
                                                    style={{ display: 'inline-block', textTransform: 'capitalize' }}

                                                    repeat={Infinity}
                                                />

                                            )}


                                        </div>
                                    )}

                                </div>
                            </div>
                            {ssaiHovered && (
                                <div className='bento-hovered-container'>
                                    <img src={ssaiHovered} alt="An abstract representation of cutting-edge technology and innovation, reflecting brosfe's expertise in website and app design, as well as office software innovation and AI consultancy. The image embraces a modern aesthetic with predominant 
                                                    modern technology use cases and necessities for effective work at a modern office and overall in a community, evoking professionalism and creativity."></img>
                                </div>
                            )}
                            <div className='bento-right'>


                                {selectedSoftware && (
                                    <div style={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'center', alignItems: 'center',
                                        height: '100%',
                                    }}>
                                        {ssaiQuotes[selectedSoftware].map((quoteData, index) => (
                                            <div key={index}
                                                style={{ marginTop: '20px' }}>
                                                <p>{quoteData.quote}</p>
                                                <p>{quoteData.author}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}


                            </div>

                        </div>

                    </div>
                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        backgroundColor: pageSectionColors[0 % pageSectionColors.length]
                    }}>

                        <div className='cfa'
                            style={{ position: 'relative' }}>
                            <CtaButton />
                            {getInTouch && (

                                <button onClick={() => handleCancel()}
                                    className='cancel-button'>
                                    <FaTimes />
                                </button>


                            )}
                            {getInTouch && (

                                <div
                                    className='cfa-logo-container'>
                                    <img src={logoMini} alt='brosfe company logo' className='logo-cfa'></img>
                                </div>


                            )}
                            {getInTouch && (

                                <div style={{
                                    display: 'flex', justifyContent: 'space-evenly', width: '100%',
                                    boxShadow: '4px 4px 12px rgba(255, 216, 178, 0.5)',
                                    transform: emailUs ? "translateY(-10px)" : '',

                                }}>
                                    <div style={{
                                        backgroundColor: '#aaffaa', padding: '10px',
                                        filter: emailUs ? "grayscale(100%)" : "none",
                                        opacity: emailUs ? '0.5' : '1',
                                        border: callUs ? '2px solid #474554' : '',
                                        borderRadius: callUs ? '10px 4px' : '',
                                        fontWeight: callUs ? 'bold' : 'normal',
                                    }}
                                        className='contact-item'
                                        onClick={handleCallUs}>
                                        <p>
                                            <FaPhone /> <a href="tel:+251974052565" >{callUs_msg}</a>
                                        </p>
                                    </div>

                                    <div style={{
                                        backgroundColor: '#aaaaff', padding: '10px',
                                        cursor: 'pointer', border: emailUs ? '2px solid #474554' : '',
                                        borderRadius: emailUs ? '10px 4px' : '',
                                        fontWeight: emailUs ? 'bold' : 'normal',
                                        filter: callUs ? "grayscale(100%)" : "none",
                                        opacity: callUs ? '0.5' : '1'
                                    }}
                                        className='contact-item'
                                        onClick={handleEmailUs}
                                    >
                                        <p>
                                            <FaEnvelope /> {emailUs_msg}
                                        </p>

                                    </div>
                                </div>

                            )}
                            {emailUs && getInTouch && (
                                <div className='reach-you-form-container'>
                                    <ReachYouForm />
                                </div>



                            )}


                        </div>

                        <div className='portfolio-others'>
                            <LinkToPortfolios headerText={headerText} listItems={PortfolioListItems} sectionRefs={[webDesignRef, brandDesignRef, officeSoftwareAIRef]} />
                        </div>
                    </div>
                    <Footer />


                </div>
            </div>


        </div>


    );
};
export default Transition(Portfolio, 'Portfolio');


