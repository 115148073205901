import React, { useEffect, useState, useMemo } from 'react';
import { useAppContext } from '../AppContext';

const ProjectDetails = ({ selectedService, selectedOption }) => {

  const { currentLanguage, setPage } = useAppContext();

  const [featureList, setFeatureList] = useState({});

  useEffect(() => {
    const updateFeatureList = () => {
      if (selectedService && selectedOption) {
        currentLanguage === 'English' ?
          setFeatureList(
            {
              'webDesign': {
                Basic: {
                  title: 'Basic Website',
                  summary: ['Up to 5 distinct navigational sections, suitable for simpler projects.'],
                  features: {
                    'Home page': 'Introduce your business or service.',
                    'Services/Products page': 'Outline what you offer.',
                    'Contact page': 'Provide ways to get in touch.',
                    'About Us page': 'Share basic information about your business.',
                    'Contact Form': 'Simple form with fields for name, email, and message.',
                    'Basic Navigation': 'Clear and straightforward navigation menu.',
                    'Mobile-friendly Design': 'Ensure a good user experience on mobile devices.'
                  },
                },
                'Intermediate': {
                  title: 'Intermediate Website',
                  summary: ['6-15 distinct navigational sections, suitable for moderate-sized projects.'],
                  features: {
                    'All features of a Basic Website': 'See basic website service type for details.',
                    'User Authentication': 'Allow users to create accounts and log in.',
                    'Basic Database Integration': 'Store and retrieve data from a database.',
                    'Responsive Design': 'Optimize the site for various screen sizes.'
                  },
                },
                'Advanced': {
                  title: 'Advanced Website',
                  summary: ['16 or more distinct navigational sections, suitable for complex and feature-rich projects.'],
                  features: {
                    'All features of a Basic and Intermediate Website': 'See basic and intermediate website service types for details.',
                    'Real-time Updates': 'Dynamic content that updates without requiring page reload.',
                    'Advanced User Interactions': 'Interactive elements like chat systems or user forums.',
                    'Custom Graphics and Animations': 'Unique visual elements tailored to the brand.',
                    'Customizable Themes': 'Allow users to personalize the appearance of the site.'
                  },
                },
              },
              'appDesign': {
                'Basic': {
                  title: 'Basic Mobile App',
                  summary: [
                    "Crafted for mobile excellence, our Basic Mobile App ensures a seamless and engaging user experience on handheld devices."
                  ],
                  features: {
                    'Responsive Design': 'Ensures optimal display and functionality across various mobile devices.',
                    'Touch-Friendly Navigation': 'Intuitive navigation designed for touch interaction, enhancing user experience.',
                    'Mobile-Optimized Content': 'Tailored content for smaller screens, maintaining clarity and readability.',
                    'Offline Functionality': 'Basic offline capabilities for continued user engagement in low-connectivity scenarios.',
                    'Push Notifications': 'Simple push notifications to keep users informed and engaged with your updates.',
                    'Basic Navigation': 'Clear and straightforward navigation menu.',
                    'Device Integration': 'Utilizes device capabilities like camera, GPS, etc., for enhanced functionalities.',
                    'Streamlined Sections for Mobile': 'Home | Services | Contact | About Us | Contact Form'
                  }
                },
                'Intermediate': {
                  title: 'Intermediate Mobile App',
                  summary: [
                    "Crafted for Progress! Elevate your mobile presence with our Intermediate Mobile App. Building on the foundation of the Basic Mobile App, this service adds advanced features for a more dynamic user experience."
                  ],
                  features: {
                    'All features of a Basic Mobile App': 'See Basic Mobile App service type for details.',
                    'Advanced Analytics': 'In-depth insights into user interactions and app performance.',
                    'Enhanced User Engagement': 'Features like gamification or interactive elements for increased engagement.',
                    'Personalized User Experience': 'Tailored content and features based on user preferences.'
                  }
                },
                'Advanced': {
                  title: 'Advanced Mobile App',
                  summary: [
                    "Pinnacle of Innovation! Unleash the full potential of your mobile strategy with our Advanced Mobile App. Building on the strengths of the Basic and Intermediate Mobile Apps, this service introduces cutting-edge features for an unparalleled user experience."
                  ],
                  features: {
                    'All features of a Basic and Intermediate Mobile App': 'See Basic and Intermediate Mobile App service types for details.',
                    'Augmented Reality Integration': 'Seamlessly blend the digital and physical worlds for a heightened user experience.',
                    'Advanced Security Measures': 'Implement robust security protocols to safeguard user data and app integrity.',
                    'Custom API Integrations': 'Connect your app with third-party services and tools for expanded functionality.'
                  }
                }
              },
              'brandDesign': {
                'Logo Design': {
                  title: 'Logo Design Services',
                  summary: [
                    "Craft Your Identity: Our Logo Design Services focus on creating unique and memorable symbols that define and distinguish your brand."
                  ],
                  features: {}
                },
                'Branding': {
                  title: 'Branding Services',
                  summary: [
                    "Build Your Brand: Our Branding Services encompass a holistic approach to establish and strengthen your brand identity across various touchpoints."
                  ],
                  features: {}
                },
                'Business Card': {
                  title: 'Business Card Design Services',
                  summary: [
                    "Leave an Impression: Our Business Card Design Services focus on creating striking and memorable cards that make a lasting impression."
                  ],
                  features: {}
                },
                'Marketing Material': {
                  title: 'Marketing/Promotional Designs',
                  summary: [
                    "Promote your business with style: Our Marketing/Promotional Services ensure a unique, polished, and professional look for your marketing/promotional activities."
                  ],
                  features: {}
                },
                'Graphics Design': {
                  title: 'Graphics Design Services',
                  summary: [
                    "Visual Excellence: Our Graphics Design Services offer custom visuals that align with and enhance your brand's visual language."
                  ],
                  features: {}
                }
              }
            }) :
          setFeatureList(
            {
              'webDesign': {
                መሠረታዊ: {
                  title: 'መሠረታዊ ዌብሳይት',
                  summary: ['እስከ 5 የሚደርሱ የተለያዩ የአሰሳ ክፍሎች፣ ለቀላል ፕሮጀክቶች ተስማሚ'],
                  features: {
                    'መነሻ ገጽ': 'ንግድዎን ወይም አገልግሎትዎን ያስተዋውቃል።',
                    'የአገልግሎት/የምርት ገጽ': 'የምያቀርቡትን ምርት ወይም የሚሰጡትን አገልግሎት ተንትኖ ያቀርባል፡፡ ',
                    'የግንኙነት ገጽ': 'እርስዎ ወይም ድርጅትዎ የሚገኝበት አማራጭ፡፡',
                    'ስለ እኛ ገጽ': 'ስለ ንግድዎ መሰረታዊ መረጃ ያጋሩ።',
                    'የአድራሻ ቅጽ': 'ቀለል ያለ ቅጽ ከስም ፣ ኢሜል እና መልእክት ጋር።',
                    'መሰረታዊ ዳሰሳ': 'ግልጽ እና ቀጥተኛ የአሰሳ ምናሌ።',
                    'ለሞባይል ተስማሚ ዲዛይን': 'በሞባይል መሳሪያዎች ላይ ጥሩ የተጠቃሚ ተሞክሮ መኖሩን ያረጋግጣሉ።'
                  },
                },
                'መካከለኛ': {
                  title: 'መካከለኛ ዌብሳይት',
                  summary: ['6-15 ልዩ የአሰሳ ክፍሎች፤ መካከለኛ መጠን ላላቸው ፕሮጀክቶች ተስማሚ'],
                  features: {
                    'የመሠረታዊ ዌብሳይት ሁሉም ገፅታዎች ያካታል': 'ለዝርዝሩ መሰረታዊ የዌብሳይት ዲዛይን አገልግሎት አይነትን ይመልከቱ።',
                    'የተጠቃሚ ማረጋገጫ': 'ተጠቃሚዎች መለያ እንዲፈጥሩ፤ እንዲመዘገቡና ሲፈልጉም ልዩ በሆነ መለያቸው ዌብሳይቱ ላይ እንዲጠቀሙ እናመቻቻለን።',
                    'መሰረታዊ የውሂብ(ዳታ) ጎታ ውህደት': 'መረጃን ከውሂብ ጎታ ማስቀመጥና ማግኘት፡፡',
                    'ምቹ ዲዛይን': 'ዌብሳይትዎ ለተለያዩ የስክሪን መጠኖች ተስማሚ እንዲሆን ይደረጋል።'
                  },
                },
                'የላቀ / ከፍ ያለ': {
                  title: 'የላቀ / ከፍ ያለ ዌብሳይት',
                  summary: ['16 ወይም ከዚያ በላይ የተለዩ የአሰሳ ክፍሎች፣ ለተወሳሰቡ እና በባህሪና ይዘት ለሰፉ ፕሮጀክቶች ተስማሚ'],
                  features: {
                    'የመሠረታዊና መካከለኛ ዌብሳይት ሁሉም ገፅታዎችን ያካታል': 'ለዝርዝሩ መሰረታዊና መካከለኛ የዌብሳይት ዲዛይን አገልግሎት አይነቶችን ይመልከቱ።',
                    'ቀልጣፋና አሁናዊ የገጽ ለውጦች': 'ገጽ ዳግም መጫን ሳያስፈልገው መቀየር ያለበት የገጽ ሥፍራን ብቻ የሚቀይር፡፡',
                    'የላቀ የተጠቃሚ መስተጋብር': 'ቻትና ሶሻል ፎረም ያሉ የመገናኛ መንገዶች።',
                    'ብጁ ግራፊክስ እና እነማዎች': 'ለብራንድዎ የተበጁ ልዩ የግራፊክስ ሥራዎችና ምስላዊ አካላት፡፡',
                    'ሊበጁ የሚችሉ ገጽታዎች': 'ተጠቃሚዎች የገጹን ገጽታ በግል ምርጫቸው እንዲያበጁ ይመቻችላቸዋል።'
                  },
                },
              },
              'appDesign': {
                'መሠረታዊ': {
                  title: 'መሠረታዊ የስልክ መተግበሪያ',
                  summary: [
                    "በላቀ ደረጃ የሚሰራው የእኛ መሰረታዊ የሞባይል መተግበሪያ በእጅ በሚያዙ የስልክ መሳሪያዎች ላይ እንከን የለሽ እና አሳታፊ የተጠቃሚ ተሞክሮን ያረጋግጣል፡፡"
                  ],
                  features: {
                    'ቀልጣፋ ዲዛይን': 'በተለያዩ የሞባይል መሳሪያዎች ላይ ጥሩ እይታንና ተግባራዊነትን የሚያረጋግጥ',
                    'ለአሰሳ ምቹ': 'ለንክኪ መስተጋብር ተጠንቶ የሚነደፍና ቀላል የሆነ የአሰሳ ተግባራዊነት፣ የተጠቃሚን ተሞክሮ የሚያሳድግ',
                    'ለሞባይል ስልክ የተመቸ ይዘት': 'ለትንንሽ ስክሪኖች የተዘጋጀ ይዘት፣ ግልጽነትን እና ተነባቢነትን የጠበቀ',
                    'ያለ ኢንተርኔት ግንኙነት የሚሠራ': 'ለቀጣይና ተከታታይ የተጠቃሚ ተሳትፎን ለማረጋገጥ ያለ ኢንተርኔት ግንኙነት የሚሠራ',
                    'መረጃዊ መልእክቶች': 'ተጠቃሚዎች ከእርስዎ ዝመናዎች ጋር እንዲገናኙ የሚረዱ ቀላል መረጃውዊ መልእክቶች',
                    'መሰረታዊ አሰሳ': 'ግልጽ እና ቀጥተኛ የአሰሳ ምናሌ(ሜኑ)',
                    'የመሣሪያ ውህደት': 'እንደ ካሜራ፣ ጂፒኤስ፣ ወዘተ ያሉ የመሣሪያ ችሎታዎችን ለተሻሉ/ትጨማሪ ተግባራት የሚጠቀም',
                    'የተሳለፉ ክፍሎች': 'መነሻ | አገልግሎቶች | ያግኙን | ስለ እኛ | የአድራሻ ቅጽ'
                  }
                },
                'መካከለኛ': {
                  title: 'መካከለኛ የስልክ መተግበሪያ',
                  summary: [
                    "ለእድገት የተነደፈ! በእኛ መካከለኛ የሞባይል መተግበሪያ የሞባይል እይታና ይዞታዎን ያሳድጉ። በመሰረታዊ የሞባይል መተግበሪያ ተግባሮች ላይ መሰረት ያደረገው ይህ አገልግሎት ተጨማሪና ተለዋዋጭ የተጠቃሚ ተሞክሮን የሚያልቁ ባህሪያትን ይይዛል፡፡"
                  ],
                  features: {
                    'የመሠረታዊ የሞባይል መተግበሪያ ሁሉም ባህሪያት የያዘ': 'ለዝርዝሩ መሰረታዊ የሞባይል መተግበሪያ አገልግሎት አይነትን ይመልከቱ',
                    'የላቀ የውሂብ(ዳታ) ትንታኔ': 'በተጠቃሚ መስተጋብር እና የመተግበሪያ አፈጻጸም ላይ ጥልቅ ግንዛቤ መሰብሰብ',
                    'የተሻሻለ የተጠቃሚ ተሳትፎ': 'እንደ ጌሚፊኬሽን እና ሌሎች መስተጋብራዊ አካላት ያሉ ባህሪያት ለበለጠ ተሳትፎ ',
                    'ለግል የተበጀ የተጠቃሚ ተሞክሮ': 'በተጠቃሚ ምርጫዎች ላይ የተመሰረተ ይዘት እና ባህሪያት'
                  }
                },
                'የላቀ / ከፍ ያለ': {
                  title: 'የላቀ / ከፍ ያለ የስልክ መተግበሪያ',
                  summary: [
                    "የፈጠራ ቁንጮ! የሞባይል ስትራቴጂዎን ሙሉ አቅም ባለውና በላቀ የሞባይል መተግበሪያ ለደንበኞችዎ ያድርሱ፡፡ በመሰረታዊ እና መካከለኛ የሞባይል አፕሊኬሽኖች ጥንካሬዎች ላይ በመመስረት ይህ አገልግሎት ወደር በሌለው የተጠቃሚ ተሞክሮ አገልግሎትዎን ያደርሳል።"
                  ],
                  features: {
                    'የመሠረታዊ እና መካከለኛ የሞባይል መተግበሪያ ሁሉም ባህሪያት ያካትታል': 'ለዝርዝሩ መሰረታዊ እና መካከለኛ የሞባይል መተግበሪያ አገልግሎት አይነቶችን ይመልከቱ',
                    'ኦግመንትድ ሪያሊቲ ውህደት': 'ለከፍተኛ የተጠቃሚ ተሞክሮ ዲጂታል እና አካላዊ ዓለሞችን ያለምንም እንከን ያዋህዱ',
                    'የላቁ የደህንነት እርምጃዎች': 'የተጠቃሚ ውሂብ እና የመተግበሪያ ታማኝነትን ለመጠበቅ ጠንካራ የደህንነት ፕሮቶኮሎችን ይተገብራል',
                    'ብጁ ኤፒአይ(API) ውህደት': 'መተግበሪያዎን ከሶስተኛ ወገን አገልግሎቶች እና መሳሪያዎች ጋር በማገናኘት አገልግሎቱን ያሰፋል'
                  }
                }
              },
              'brandDesign': {
                'የድርጅት ዓርማ': {
                  title: 'የድርጅት ዓርማ ዲዛይን አገልግሎቶች',
                  summary: [
                    "ማንነትዎን ይቅረጹ፡ የእኛ የድርጅት ዓርማ ዲዛይን አገልግሎቶቻችን ምርትና አገልግሎትዎን የሚገልጹ እና የሚለዩ ልዩ እና የማይረሱ ምልክቶችን በመፍጠር ላይ ያተኩራሉ፡፡"
                  ],
                  features: {}
                },
                'ብራንዲንግ': {
                  title: 'ብራንዲንግ አገልግሎቶች',
                  summary: [
                    "ብራንድዎን ይገንቡ፡ የብራንዲንግ አገልግሎታችን በተለያዩ የመዳሰሻ ነጥቦች ላይ የምርት መለያዎን ለማቋቋም እና ለማጠናከር አጠቃላይ አቀራረብን ያካትታሉ።"
                  ],
                  features: {}
                },
                'ቢዝነስ ካርድ': {
                  title: 'Bቢዝነስ ካርድ ዛይን አገልግሎቶች',
                  summary: [
                    "እንድምታ ይፍጠሩ፡ የኛ የቢዝነስ ካርድ ዲዛይን አገልግሎታችን ዘላቂ ስሜት የሚፈጥሩ አስገራሚ እና የማይረሱ ካርዶችን በመፍጠር ላይ ያተኩራል።"
                  ],
                  features: {}
                },
                'መረጃ/ማስታወቂያ ሥራ': {
                  title: 'መረጃ/ማስታወቂያ ሥራ ዲዛይን አገልግሎቶች',
                  summary: [
                    "ንግድዎን በስታይል ያስተዋውቁ፡ የኛ የግብይት/ማስተዋወቂያ አገልግሎታችን ለግብይት/ማስተዋወቂያ እንቅስቃሴዎ ልዩ፣ የተዋበ እና ሙያዊ እይታን ያረጋግጣል።"
                  ],
                  features: {}
                },
                'ግራፊክስ ዲዛይን': {
                  title: 'ግራፊክስ ዲዛይን አገልግሎቶች',
                  summary: [
                    "የእይታ ልቀት፡ የኛ ግራፊክስ ዲዛይን አገልግሎታችን ከምርትዎ የእይታ ቋንቋ ጋር የሚጣጣሙ እና የሚያሻሽሉ ብጁ ምስሎችን ያቀርባሉ።"
                  ],
                  features: {}
                }
              }
            });
      }
    };

    updateFeatureList();
  }, [selectedService, selectedOption]);


  useMemo(() => {
    if (selectedService && selectedOption) {
      currentLanguage === 'English' ?
      setFeatureList(
        {
          'webDesign': {
            Basic: {
              title: 'Basic Website',
              summary: ['Up to 5 distinct navigational sections, suitable for simpler projects.'],
              features: {
                'Home page': 'Introduce your business or service.',
                'Services/Products page': 'Outline what you offer.',
                'Contact page': 'Provide ways to get in touch.',
                'About Us page': 'Share basic information about your business.',
                'Contact Form': 'Simple form with fields for name, email, and message.',
                'Basic Navigation': 'Clear and straightforward navigation menu.',
                'Mobile-friendly Design': 'Ensure a good user experience on mobile devices.'
              },
            },
            'Intermediate': {
              title: 'Intermediate Website',
              summary: ['6-15 distinct navigational sections, suitable for moderate-sized projects.'],
              features: {
                'All features of a Basic Website': 'See basic website service type for details.',
                'User Authentication': 'Allow users to create accounts and log in.',
                'Basic Database Integration': 'Store and retrieve data from a database.',
                'Responsive Design': 'Optimize the site for various screen sizes.'
              },
            },
            'Advanced': {
              title: 'Advanced Website',
              summary: ['16 or more distinct navigational sections, suitable for complex and feature-rich projects.'],
              features: {
                'All features of a Basic and Intermediate Website': 'See basic and intermediate website service types for details.',
                'Real-time Updates': 'Dynamic content that updates without requiring page reload.',
                'Advanced User Interactions': 'Interactive elements like chat systems or user forums.',
                'Custom Graphics and Animations': 'Unique visual elements tailored to the brand.',
                'Customizable Themes': 'Allow users to personalize the appearance of the site.'
              },
            },
          },
          'appDesign': {
            'Basic': {
              title: 'Basic Mobile App',
              summary: [
                "Crafted for mobile excellence, our Basic Mobile App ensures a seamless and engaging user experience on handheld devices."
              ],
              features: {
                'Responsive Design': 'Ensures optimal display and functionality across various mobile devices.',
                'Touch-Friendly Navigation': 'Intuitive navigation designed for touch interaction, enhancing user experience.',
                'Mobile-Optimized Content': 'Tailored content for smaller screens, maintaining clarity and readability.',
                'Offline Functionality': 'Basic offline capabilities for continued user engagement in low-connectivity scenarios.',
                'Push Notifications': 'Simple push notifications to keep users informed and engaged with your updates.',
                'Basic Navigation': 'Clear and straightforward navigation menu.',
                'Device Integration': 'Utilizes device capabilities like camera, GPS, etc., for enhanced functionalities.',
                'Streamlined Sections for Mobile': 'Home | Services | Contact | About Us | Contact Form'
              }
            },
            'Intermediate': {
              title: 'Intermediate Mobile App',
              summary: [
                "Crafted for Progress! Elevate your mobile presence with our Intermediate Mobile App. Building on the foundation of the Basic Mobile App, this service adds advanced features for a more dynamic user experience."
              ],
              features: {
                'All features of a Basic Mobile App': 'See Basic Mobile App service type for details.',
                'Advanced Analytics': 'In-depth insights into user interactions and app performance.',
                'Enhanced User Engagement': 'Features like gamification or interactive elements for increased engagement.',
                'Personalized User Experience': 'Tailored content and features based on user preferences.'
              }
            },
            'Advanced': {
              title: 'Advanced Mobile App',
              summary: [
                "Pinnacle of Innovation! Unleash the full potential of your mobile strategy with our Advanced Mobile App. Building on the strengths of the Basic and Intermediate Mobile Apps, this service introduces cutting-edge features for an unparalleled user experience."
              ],
              features: {
                'All features of a Basic and Intermediate Mobile App': 'See Basic and Intermediate Mobile App service types for details.',
                'Augmented Reality Integration': 'Seamlessly blend the digital and physical worlds for a heightened user experience.',
                'Advanced Security Measures': 'Implement robust security protocols to safeguard user data and app integrity.',
                'Custom API Integrations': 'Connect your app with third-party services and tools for expanded functionality.'
              }
            }
          },
          'brandDesign': {
            'Logo Design': {
              title: 'Logo Design Services',
              summary: [
                "Craft Your Identity: Our Logo Design Services focus on creating unique and memorable symbols that define and distinguish your brand."
              ],
              features: {}
            },
            'Branding': {
              title: 'Branding Services',
              summary: [
                "Build Your Brand: Our Branding Services encompass a holistic approach to establish and strengthen your brand identity across various touchpoints."
              ],
              features: {}
            },
            'Business Card': {
              title: 'Business Card Design Services',
              summary: [
                "Leave an Impression: Our Business Card Design Services focus on creating striking and memorable cards that make a lasting impression."
              ],
              features: {}
            },
            'Marketing Material': {
              title: 'Marketing/Promotional Designs',
              summary: [
                "Promote your business with style: Our Marketing/Promotional Services ensure a unique, polished, and professional look for your marketing/promotional activities."
              ],
              features: {}
            },
            'Graphics Design': {
              title: 'Graphics Design Services',
              summary: [
                "Visual Excellence: Our Graphics Design Services offer custom visuals that align with and enhance your brand's visual language."
              ],
              features: {}
            }
          }
        }) :
      setFeatureList(
        {
          'webDesign': {
            መሠረታዊ: {
              title: 'መሠረታዊ ዌብሳይት',
              summary: ['እስከ 5 የሚደርሱ የተለያዩ የአሰሳ ክፍሎች፣ ለቀላል ፕሮጀክቶች ተስማሚ'],
              features: {
                'መነሻ ገጽ': 'ንግድዎን ወይም አገልግሎትዎን ያስተዋውቃል።',
                'የአገልግሎት/የምርት ገጽ': 'የምያቀርቡትን ምርት ወይም የሚሰጡትን አገልግሎት ተንትኖ ያቀርባል፡፡ ',
                'የግንኙነት ገጽ': 'እርስዎ ወይም ድርጅትዎ የሚገኝበት አማራጭ፡፡',
                'ስለ እኛ ገጽ': 'ስለ ንግድዎ መሰረታዊ መረጃ ያጋሩ።',
                'የአድራሻ ቅጽ': 'ቀለል ያለ ቅጽ ከስም ፣ ኢሜል እና መልእክት ጋር።',
                'መሰረታዊ ዳሰሳ': 'ግልጽ እና ቀጥተኛ የአሰሳ ምናሌ።',
                'ለሞባይል ተስማሚ ዲዛይን': 'በሞባይል መሳሪያዎች ላይ ጥሩ የተጠቃሚ ተሞክሮ መኖሩን ያረጋግጣሉ።'
              },
            },
            'መካከለኛ': {
              title: 'መካከለኛ ዌብሳይት',
              summary: ['6-15 ልዩ የአሰሳ ክፍሎች፤ መካከለኛ መጠን ላላቸው ፕሮጀክቶች ተስማሚ'],
              features: {
                'የመሠረታዊ ዌብሳይት ሁሉም ገፅታዎች ያካታል': 'ለዝርዝሩ መሰረታዊ የዌብሳይት ዲዛይን አገልግሎት አይነትን ይመልከቱ።',
                'የተጠቃሚ ማረጋገጫ': 'ተጠቃሚዎች መለያ እንዲፈጥሩ፤ እንዲመዘገቡና ሲፈልጉም ልዩ በሆነ መለያቸው ዌብሳይቱ ላይ እንዲጠቀሙ እናመቻቻለን።',
                'መሰረታዊ የውሂብ(ዳታ) ጎታ ውህደት': 'መረጃን ከውሂብ ጎታ ማስቀመጥና ማግኘት፡፡',
                'ምቹ ዲዛይን': 'ዌብሳይትዎ ለተለያዩ የስክሪን መጠኖች ተስማሚ እንዲሆን ይደረጋል።'
              },
            },
            'የላቀ / ከፍ ያለ': {
              title: 'የላቀ / ከፍ ያለ ዌብሳይት',
              summary: ['16 ወይም ከዚያ በላይ የተለዩ የአሰሳ ክፍሎች፣ ለተወሳሰቡ እና በባህሪና ይዘት ለሰፉ ፕሮጀክቶች ተስማሚ'],
              features: {
                'የመሠረታዊና መካከለኛ ዌብሳይት ሁሉም ገፅታዎችን ያካታል': 'ለዝርዝሩ መሰረታዊና መካከለኛ የዌብሳይት ዲዛይን አገልግሎት አይነቶችን ይመልከቱ።',
                'ቀልጣፋና አሁናዊ የገጽ ለውጦች': 'ገጽ ዳግም መጫን ሳያስፈልገው መቀየር ያለበት የገጽ ሥፍራን ብቻ የሚቀይር፡፡',
                'የላቀ የተጠቃሚ መስተጋብር': 'ቻትና ሶሻል ፎረም ያሉ የመገናኛ መንገዶች።',
                'ብጁ ግራፊክስ እና እነማዎች': 'ለብራንድዎ የተበጁ ልዩ የግራፊክስ ሥራዎችና ምስላዊ አካላት፡፡',
                'ሊበጁ የሚችሉ ገጽታዎች': 'ተጠቃሚዎች የገጹን ገጽታ በግል ምርጫቸው እንዲያበጁ ይመቻችላቸዋል።'
              },
            },
          },
          'appDesign': {
            'መሠረታዊ': {
              title: 'መሠረታዊ የስልክ መተግበሪያ',
              summary: [
                "በላቀ ደረጃ የሚሰራው የእኛ መሰረታዊ የሞባይል መተግበሪያ በእጅ በሚያዙ የስልክ መሳሪያዎች ላይ እንከን የለሽ እና አሳታፊ የተጠቃሚ ተሞክሮን ያረጋግጣል፡፡"
              ],
              features: {
                'ቀልጣፋ ዲዛይን': 'በተለያዩ የሞባይል መሳሪያዎች ላይ ጥሩ እይታንና ተግባራዊነትን የሚያረጋግጥ',
                'ለአሰሳ ምቹ': 'ለንክኪ መስተጋብር ተጠንቶ የሚነደፍና ቀላል የሆነ የአሰሳ ተግባራዊነት፣ የተጠቃሚን ተሞክሮ የሚያሳድግ',
                'ለሞባይል ስልክ የተመቸ ይዘት': 'ለትንንሽ ስክሪኖች የተዘጋጀ ይዘት፣ ግልጽነትን እና ተነባቢነትን የጠበቀ',
                'ያለ ኢንተርኔት ግንኙነት የሚሠራ': 'ለቀጣይና ተከታታይ የተጠቃሚ ተሳትፎን ለማረጋገጥ ያለ ኢንተርኔት ግንኙነት የሚሠራ',
                'መረጃዊ መልእክቶች': 'ተጠቃሚዎች ከእርስዎ ዝመናዎች ጋር እንዲገናኙ የሚረዱ ቀላል መረጃውዊ መልእክቶች',
                'መሰረታዊ አሰሳ': 'ግልጽ እና ቀጥተኛ የአሰሳ ምናሌ(ሜኑ)',
                'የመሣሪያ ውህደት': 'እንደ ካሜራ፣ ጂፒኤስ፣ ወዘተ ያሉ የመሣሪያ ችሎታዎችን ለተሻሉ/ትጨማሪ ተግባራት የሚጠቀም',
                'የተሳለፉ ክፍሎች': 'መነሻ | አገልግሎቶች | ያግኙን | ስለ እኛ | የአድራሻ ቅጽ'
              }
            },
            'መካከለኛ': {
              title: 'መካከለኛ የስልክ መተግበሪያ',
              summary: [
                "ለእድገት የተነደፈ! በእኛ መካከለኛ የሞባይል መተግበሪያ የሞባይል እይታና ይዞታዎን ያሳድጉ። በመሰረታዊ የሞባይል መተግበሪያ ተግባሮች ላይ መሰረት ያደረገው ይህ አገልግሎት ተጨማሪና ተለዋዋጭ የተጠቃሚ ተሞክሮን የሚያልቁ ባህሪያትን ይይዛል፡፡"
              ],
              features: {
                'የመሠረታዊ የሞባይል መተግበሪያ ሁሉም ባህሪያት የያዘ': 'ለዝርዝሩ መሰረታዊ የሞባይል መተግበሪያ አገልግሎት አይነትን ይመልከቱ',
                'የላቀ የውሂብ(ዳታ) ትንታኔ': 'በተጠቃሚ መስተጋብር እና የመተግበሪያ አፈጻጸም ላይ ጥልቅ ግንዛቤ መሰብሰብ',
                'የተሻሻለ የተጠቃሚ ተሳትፎ': 'እንደ ጌሚፊኬሽን እና ሌሎች መስተጋብራዊ አካላት ያሉ ባህሪያት ለበለጠ ተሳትፎ ',
                'ለግል የተበጀ የተጠቃሚ ተሞክሮ': 'በተጠቃሚ ምርጫዎች ላይ የተመሰረተ ይዘት እና ባህሪያት'
              }
            },
            'የላቀ / ከፍ ያለ': {
              title: 'የላቀ / ከፍ ያለ የስልክ መተግበሪያ',
              summary: [
                "የፈጠራ ቁንጮ! የሞባይል ስትራቴጂዎን ሙሉ አቅም ባለውና በላቀ የሞባይል መተግበሪያ ለደንበኞችዎ ያድርሱ፡፡ በመሰረታዊ እና መካከለኛ የሞባይል አፕሊኬሽኖች ጥንካሬዎች ላይ በመመስረት ይህ አገልግሎት ወደር በሌለው የተጠቃሚ ተሞክሮ አገልግሎትዎን ያደርሳል።"
              ],
              features: {
                'የመሠረታዊ እና መካከለኛ የሞባይል መተግበሪያ ሁሉም ባህሪያት ያካትታል': 'ለዝርዝሩ መሰረታዊ እና መካከለኛ የሞባይል መተግበሪያ አገልግሎት አይነቶችን ይመልከቱ',
                'ኦግመንትድ ሪያሊቲ ውህደት': 'ለከፍተኛ የተጠቃሚ ተሞክሮ ዲጂታል እና አካላዊ ዓለሞችን ያለምንም እንከን ያዋህዱ',
                'የላቁ የደህንነት እርምጃዎች': 'የተጠቃሚ ውሂብ እና የመተግበሪያ ታማኝነትን ለመጠበቅ ጠንካራ የደህንነት ፕሮቶኮሎችን ይተገብራል',
                'ብጁ ኤፒአይ(API) ውህደት': 'መተግበሪያዎን ከሶስተኛ ወገን አገልግሎቶች እና መሳሪያዎች ጋር በማገናኘት አገልግሎቱን ያሰፋል'
              }
            }
          },
          'brandDesign': {
            'የድርጅት ዓርማ': {
              title: 'የድርጅት ዓርማ ዲዛይን አገልግሎቶች',
              summary: [
                "ማንነትዎን ይቅረጹ፡ የእኛ የድርጅት ዓርማ ዲዛይን አገልግሎቶቻችን ምርትና አገልግሎትዎን የሚገልጹ እና የሚለዩ ልዩ እና የማይረሱ ምልክቶችን በመፍጠር ላይ ያተኩራሉ፡፡"
              ],
              features: {}
            },
            'ብራንዲንግ': {
              title: 'ብራንዲንግ አገልግሎቶች',
              summary: [
                "ብራንድዎን ይገንቡ፡ የብራንዲንግ አገልግሎታችን በተለያዩ የመዳሰሻ ነጥቦች ላይ የምርት መለያዎን ለማቋቋም እና ለማጠናከር አጠቃላይ አቀራረብን ያካትታሉ።"
              ],
              features: {}
            },
            'ቢዝነስ ካርድ': {
              title: 'Bቢዝነስ ካርድ ዛይን አገልግሎቶች',
              summary: [
                "እንድምታ ይፍጠሩ፡ የኛ የቢዝነስ ካርድ ዲዛይን አገልግሎታችን ዘላቂ ስሜት የሚፈጥሩ አስገራሚ እና የማይረሱ ካርዶችን በመፍጠር ላይ ያተኩራል።"
              ],
              features: {}
            },
            'መረጃ/ማስታወቂያ ሥራ': {
              title: 'መረጃ/ማስታወቂያ ሥራ ዲዛይን አገልግሎቶች',
              summary: [
                "ንግድዎን በስታይል ያስተዋውቁ፡ የኛ የግብይት/ማስተዋወቂያ አገልግሎታችን ለግብይት/ማስተዋወቂያ እንቅስቃሴዎ ልዩ፣ የተዋበ እና ሙያዊ እይታን ያረጋግጣል።"
              ],
              features: {}
            },
            'ግራፊክስ ዲዛይን': {
              title: 'ግራፊክስ ዲዛይን አገልግሎቶች',
              summary: [
                "የእይታ ልቀት፡ የኛ ግራፊክስ ዲዛይን አገልግሎታችን ከምርትዎ የእይታ ቋንቋ ጋር የሚጣጣሙ እና የሚያሻሽሉ ብጁ ምስሎችን ያቀርባሉ።"
              ],
              features: {}
            }
          }
        });
  }
  }, [selectedService, selectedOption]);



  const renderFeaturesList = () => {
    const features = featureList[selectedOption]?.[selectedService]?.features;

    if (!features || Object.keys(features).length === 0) {
      return null;
    }

    const features_header = currentLanguage==='English'?'Key Features':'ዋና ዋና ባህሪያት';

    return (
      <>
        <p style={{ marginBottom: '10px', fontSize: '16px', border: '1px solid #474554', width: '70%', padding: '5px 5px' }}>
          <strong>{features_header} </strong>
        </p>
        <ul style={{ listStyle: 'none' }}>
          {Object.entries(features).map(([key, value]) => (
            <li style={{ marginBottom: '5px' }} key={key}>
              <span className='features-list'>{key}</span> {value}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div className='features-container'>
      {featureList[selectedOption]?.[selectedService]?.title && (
        <h3 className='features-header'>{featureList[selectedOption][selectedService].title}</h3>
      )}
      {featureList[selectedOption]?.[selectedService]?.summary && (
        <p style={{ marginBottom: '10px' }}>
          <span style={{ marginRight: '10px', fontWeight: 'bold', fontSize: '16px' }}>{featureList[selectedOption][selectedService].summary}</span>
        </p>
      )}
      {renderFeaturesList()}
    </div>
  );
};

export default ProjectDetails;