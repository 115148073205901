import { useEffect } from 'react';
import { useAppContext } from '../AppContext';
import './ContactUs.css'
import Footer from './Footer';
import InputForm from './InputForm';
import Transition from './Transition';
import ScreenSize from './ScreenSize';
import { FaPhone, FaEnvelope, FaLinkedin } from 'react-icons/fa';


import ceoPic from '../assets/images/team/ceo-closeup.png'


function ContactUs() {
  const { currentLanguage, setPage } = useAppContext();
  useEffect(() => {
    setPage('ContactUs');
  }, [])


 const header_title = currentLanguage === 'English'?'Ready to take your digital presence to the next level?':
 'የእርስዎን ዲጂታል ቅርበትና አቅርቦት ወደሚቀጥለው ደረጃ ከፍ ለማድረግ ዝግጁ ነዎት?';

 const subheader_title = currentLanguage === 'English'?
 'Let\'s get in touch! Whether it\'s through email, a friendly call, or a quick form, we await and are eager to hear about your service and product needs. Let\'s craft something amazing together!':
 'እንገናኝ! በኢሜል፣ ወዳጃዊ ጥሪ ወይም ፈጣን ቅጽ ስለ አገልግሎትዎ እና የምርት ፍላጎቶችዎ ለመስማት ነቅተን እንጠብቃለን። አስደናቂ ነገሮችን አብረን እንስራ!';

 const call_invite = currentLanguage === 'English'?
 'Care to know more?': 'የበለጠ ለማወቅ ይፈልጋሉ?';

 const call_us = currentLanguage==='English'?
 'Call Us': 'ይደውሉልን';
  return (
    <div className='main-container'>
     
        <div className='sub-container'>

          {/* <ScreenSize /> */}

          <div className="motto">
            <h1>{header_title}</h1>
            <p>{subheader_title}</p>
          </div>
          <div className='contacts-container'
            style={{
              position: 'relative',
            }}>
            <InputForm />
            <div className='contacts-more'
            >

              <img src={ceoPic} alt='A picture of the CEO of the company, brosfe.'
              style={{boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)'}}>
                
              </img>
             
             
              <p
              >{call_invite}</p>
              
              <div className='contacts-quick-container'>
                <div style={{ backgroundColor: '#aaffaa', padding: '10px', }}
                  className='contact-item'>
                  <p>
                    <FaPhone /> <a href="tel:+251974052565"  target="_blank">{call_us}</a>
                  </p>
                </div>
                {/* <div style={{ backgroundColor: '#aaaaff', padding: '10px', }}
                  className='contact-item'>
                  <p>
                    <FaLinkedin /> <a href="https://www.linkedin.com/in/biruke-abraha/"  target="_blank">Visit Our LinkedIn</a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>



          <Footer />


        </div>

      
      

    </div>

  );
}

export default Transition(ContactUs, 'Contact Us');
