import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../AppContext';

const BlogPost = ({ featuredBlog, otherBlogs }) => {
  const { currentLanguage, setPage } = useAppContext();


  const { id } = useParams();
  


  const blogId = parseInt(id, 10);
  const header_featured = otherBlogs.find((post) => post.id === blogId) ? '' : currentLanguage==='English'?'Featured blog':'ተለይቶ የቀረበ';
  const blog = otherBlogs.find((post) => post.id === blogId) || featuredBlog;
  




  if (!blog) {
    return <p>Blog post not found!</p>;
  }

  const author = currentLanguage === 'English' ? 'Author: ' : 'ጸሓፊ: ';


  return (
    <div className="blog-featured">
      <h3>{header_featured}</h3>
      <div className='blog-title-container'>
        <h1 style={{color:  blog.id % 3 === 1 ? '#ebb176' : blog.id%3===2?'#ffd230':'#c7e6d7'}}>{blog.title}
        </h1>

      </div>
      
      <h4 style={{
        fontStyle: 'italic', fontWeight: 'bold',
      }}>{author} {blog.author}</h4>
      <h4 style={{
        fontStyle: 'italic', fontWeight: 'bold',
      }}>{blog.date}
      </h4>
      <div className='blog-display'>
      
      <div dangerouslySetInnerHTML={{ __html: blog.content1 }}
     style={{whiteSpace: 'pre-wrap',}}>
           </div>
           <img src={blog.image} alt="Technology related picture that corresponds to the blog topic." />
           <div dangerouslySetInnerHTML={{ __html: blog.content2 }}
     style={{whiteSpace: 'pre-wrap' }}>
           </div>
      
    
      </div>
     
       
      <div
        className='blog-decorator-top'
        style={{backgroundColor: blog.id % 3 === 1 ? '#ebb176' : blog.id%3===2?'#ffd230':'#c7e6d7'}}>

        </div>
        <div
        className='blog-decorator-bottom'
        style={{backgroundColor: blog.id % 3 === 1 ? '#ebb176' : blog.id%3===2?'#ffd230':'#c7e6d7'}}>

        </div>
        <div
        className='blog-decorator-bottom-small'>

        </div>
    </div>

  );
};

export default BlogPost;
